import React, { useContext, useEffect, useState } from 'react';
import web3 from 'web3';
import { Alert, Button, Card, Col, Input, message, Modal, Popover, Row, Slider, Table, Tabs, Tag, Tooltip } from 'antd';
import get from 'lodash/fp/get';
import { LoadingOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import styled from 'styled-components';
import {
  isMobile,
  gradient,
  doubleBalance,
  tokenValue,
  formatMoney,
  textCompare,
  loadObject,
} from '../../../utils/utils';
import WalletProvider, { WalletContext } from './WalletContext';
import Constants from './constants';
import Settings from './Settings';
import TokenChart from './TokenChart';
import Contract from './Contract';
import Chains from './Chains';
import Money from './Money';
import TokenLogo from './TokenLogo';

import Transactions from './Transactions';
import ScanLink from './ScanLink';
import Nfts from './Nfts';

const mob = isMobile();

const Diagonal = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0px;
  background: linear-gradient(170deg, transparent 65%, ${({ theme }) => {
    const [c1, c2] = gradient(Constants.THEMES[theme].PRIMARY);
    return `${c1} 65.1%, ${c2} 100%`;
  }})});
  background-attachment: fixed;
`;

const Wrapper = styled.div`
  box-shadow: 0 50px 100px -20px rgb(50 50 93 / 25%), 0 30px 60px -30px rgb(0 0 0 / 30%);
  border-radius: 20px;
  overflow: hidden;
  position: relative;
  margin: 0px auto;
  max-width: 800px;
  width: 100%;
  z-index: 10;

  * {
    font-family: 'Lato', sans-serif;
  }
`;

const Wallets = styled.div`
  padding: 20px;
`;

const Symbol = styled.div`
  font-weight: bold;
`;

const Address = styled.span`
  font-size: 12px;
  color: #444;
`;

const TotalWrap = styled.div`
  font-size: 40px;
  text-align: center;
  margin-bottom: 18px;
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
`;

const Footer = styled.div``;

const Tile = styled.div`
  height: 140px;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin: 10px;
`;

const TileHeader = styled.h4`
  font-size: 20px;
  font-weight: bold;
  margin-top: 0px;
  margin-bottom: 10px;
`;

const CardHeader = styled.div`
  background-color: ${({ theme }) => Constants.THEMES[theme].PRIMARY};
  padding: 15px 15px 20px 22.5px;
`;

const CardTitle = styled.div`
  flex: 1;
  color: white;
  font-weight: bold;
  font-size: 20px;
`;

const NameInput = styled.input`
  background: transparent;
  border: none;
  webkit-box-shadow: none;
  outline: none;
  font-weight: bold;
  font-size: 16px;
  padding: 5px;
  width: 100%;
  &::placeholder {
    font-style: italic;
  }
`;

const TabsWrapper = styled.div`
  .ant-tabs-nav,
  .ant-tabs-nav-list {
    width: 100% !important;
    margin-bottom: 0px;
  }

  .ant-tabs-tab {
    flex: 1;
    justify-content: center;
    font-weight: bold;
  }

  .ant-tabs-nav > div:nth-of-type(1) {
    display: flex !important;
    width: 100% !important;
  }
`;

const CardActions = styled.div``;

export const Action = ({ logo, url, title }) => {
  return (
    <Tooltip title={title}>
      <a href={url} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px' }}>
        <Icon src={require(`./images/logos/${logo}`).default} />
      </a>
    </Tooltip>
  );
};

const getWalletTotal = ({ balance, tokenPrices }) => {
  return balance
    ? balance.reduce((agg, record) => {
        return agg + tokenValue({ record, tokenPrices });
      }, 0)
    : 0;
};

export const WalletCol = ({ walletId }) => {
  const { walletNames } = useContext(WalletContext);

  if (!walletId) return null;

  const k = Object.keys(walletNames).find(w => w.toLowerCase() === walletId.toLowerCase());

  return (
    <div>
      {k && walletNames[k] && <Symbol>{walletNames[k]}</Symbol>}
      <Contract contract={walletId} />
    </div>
  );
};

const Wallet = ({ walletId, index }) => {
  const { deleteWalletId, balances, tokenPrices, walletNames, setWalletName } = useContext(WalletContext);

  const balance = balances[walletId];
  const total = getWalletTotal({ balance, tokenPrices });
  const defaultName = `Wallet ${index + 1}`;

  return (
    <Card
      size="small"
      title={
        <NameInput
          type="text"
          value={get(walletId, walletNames, '')}
          placeholder={defaultName}
          onChange={e => {
            setWalletName({ walletId, name: e.target.value });
          }}
        />
      }
      headStyle={{ border: 'none' }}
      style={{
        boxShadow: '0px 5px 25px rgba(0, 0, 0, 0.8)',
        border: 'none',
      }}
      bodyStyle={{ paddingTop: 0 }}
      actions={[
        <Contract contract={walletId} />,
        <div
          key="delete"
          onClick={() => {
            deleteWalletId(walletId);
          }}
        >
          Remove
        </div>,
      ]}
    >
      {balance && balance.error && <Alert message="Failed to get balance" />}
      {!balance && (
        <span>
          <LoadingOutlined style={{ marginRight: 5 }} /> Loading...
        </span>
      )}
      {balance && <Money style={{ fontSize: 20, fontWeight: 'bold' }} amount={total} />}
    </Card>
  );
};

const Total = () => {
  const { balances, tokenPrices, moontipliers } = useContext(WalletContext);
  const total = Object.entries(balances).reduce((agg, [, balance]) => {
    return agg + getWalletTotal({ balance, tokenPrices });
  }, 0);

  const hasMoontiplier = Object.entries(moontipliers).some(([, v]) => v > 0);

  useEffect(() => {
    document.title = `Wallet $${formatMoney(total)}`;
  });

  return (
    <TotalWrap>
      <Money
        key={hasMoontiplier}
        amount={total}
        style={{
          color: 'white',
          fontStyle: hasMoontiplier ? 'italic' : 'regular',
          textShadow: '0px 1px 2px rgba(0, 0, 0, 0.2)',
        }}
      />{' '}
      {hasMoontiplier && (
        <Tooltip title="This would be your total considering all the moontipliers">
          <span style={{ color: 'white' }}>*</span>
        </Tooltip>
      )}
    </TotalWrap>
  );
};

const WalletAdd = ({ addVisible, setAddVisible }) => {
  const [newWalletId, setNewWalletId] = useState('');

  const { addWalletId, walletIds } = useContext(WalletContext);

  const add = wid => {
    if (web3.utils.isAddress(wid)) {
      addWalletId(wid);
      setNewWalletId('');
      setAddVisible(false);
    } else {
      message.error('Address is invalid!');
    }
  };

  return (
    <Modal visible={addVisible} onCancel={() => setAddVisible(false)} footer={null} title={<b>Add Wallet</b>}>
      <Input.Group compact style={{ display: 'flex' }}>
        <Input value={newWalletId} onChange={e => setNewWalletId(e.target.value)} placeholder="0x..." />
        <Button
          icon={<PlusOutlined />}
          onClick={() => {
            add(newWalletId);
          }}
        />
      </Input.Group>
      <Table
        style={{ marginTop: 20 }}
        size="small"
        pagination={false}
        columns={[
          {
            title: 'Previously Used',
            render: r => <WalletCol walletId={r} />,
          },
          {
            render: r => (
              <Button
                disabled={walletIds.find(x => x === r)}
                type="link"
                onClick={() => {
                  add(r);
                }}
              >
                Add
              </Button>
            ),
          },
        ]}
        dataSource={loadObject({ key: 'usedWallets', defaultValue: [] })}
      />
    </Modal>
  );
};

const Wally = () => {
  const { walletIds, tokens, tokenPrices, lastUpdated, addIgnoreToken, theme, moontipliers, setMoontiplier, setNft } =
    useContext(WalletContext);

  const [featureVisible, setFeatureVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [textFilter, setTextFilter] = useState('');

  const isStarted = walletIds.length > 0;

  const balanceCol = {
    dataIndex: 'balance',
    render: (v, record) => {
      const value = tokenValue({ record, tokenPrices });
      const moontiplier = Constants.MOONTIPLIERS[moontipliers[record.contract_address] || 0];

      return (
        <div>
          <Money
            amount={value}
            suffix={
              moontiplier > 1 && (
                <Tag
                  closable
                  style={{ marginLeft: 5 }}
                  onClose={() => {
                    setMoontiplier({ tokenId: record.contract_address, value: 0 });
                  }}
                >
                  {moontiplier}x
                </Tag>
              )
            }
          />
          <div style={{ fontSize: 14, color: '#777' }}>{doubleBalance(record).toLocaleString()}</div>
        </div>
      );
    },
  };

  const tickerCol = {
    dataIndex: 'contract_ticker_symbol',
    key: 'contract_ticker_symbol',
    render: (v, { contract_address }) => {
      return (
        <div>
          <Symbol>{v}</Symbol>
          <Address>
            <Contract contract={contract_address} />
          </Address>
        </div>
      );
    },
  };

  const ignoreCol = {
    key: 'ignore',
    width: 60,
    render: (v, record) => {
      return (
        <Button type="link" onClick={() => addIgnoreToken(record)}>
          Hide
        </Button>
      );
    },
  };

  const actionsRenderer = record => {
    let actions = null;

    const charts = (
      <>
        <Action title="PooCoin" logo="poocoin.png" url={`https://poocoin.app/tokens/${record.contract_address}`} />
        <Action title="DexGuru" logo="dexguru.png" url={`https://dex.guru/token/${record.contract_address}`} />
      </>
    );

    const scanLink = <ScanLink chainId={record.chainId} tokenId={record.contract_address} />;

    if (record.chainId === Constants.CHAINS.BSC.id) {
      actions = (
        <>
          {charts}
          {scanLink}
          <Action
            title="Pancake Swap"
            logo="pancakeswap.png"
            url={`https://exchange.pancakeswap.finance/#/swap?outputCurrency=${record.contract_address}`}
          />
        </>
      );
    } else if (record.chainId === Constants.CHAINS.ERC.id) {
      actions = (
        <>
          {charts}
          {scanLink}
          <Action
            title="UniSwap"
            logo="uniswap.png"
            url={`https://app.uniswap.org/#/swap?outputCurrency=${record.contract_address}`}
          />
        </>
      );
    } else if (record.chainId === Constants.CHAINS.POLYGON.id) {
      actions = scanLink;
    } else if (record.chainId === Constants.CHAINS.FANTOM.id) {
      actions = scanLink;
    }

    const marks = {};
    Constants.MOONTIPLIERS.forEach((k, i) => (marks[i] = `${k}x`));

    return (
      <div style={{ display: 'flex' }}>
        {actions}
        <Popover
          title="Moontiplier"
          trigger="click"
          content={
            <div>
              <p>Test what would happen if this token mooned</p>
              <Slider
                tooltipVisible={false}
                marks={marks}
                step={1}
                min={0}
                max={4}
                value={moontipliers[record.contract_address]}
                onChange={value => {
                  setMoontiplier({
                    tokenId: record.contract_address,
                    value,
                  });
                }}
              />
            </div>
          }
        >
          <div style={{ cursor: 'pointer' }}>
            {moontipliers[record.contract_address] > 0 && (
              <div>
                <span
                  className="iconify"
                  data-icon="emojione:rocket"
                  data-inline="false"
                  style={{ height: 20, width: 20 }}
                />
              </div>
            )}
            {!moontipliers[record.contract_address] && (
              <div>
                <span
                  className="iconify"
                  data-icon="emojione-monotone:rocket"
                  data-inline="false"
                  style={{ height: 20, width: 20 }}
                />
              </div>
            )}
          </div>
        </Popover>
      </div>
    );
  };

  const columns = [
    isMobile()
      ? null
      : {
          key: 'logo',
          width: 60,
          render: (v, r) => {
            return (
              <TokenLogo
                key={r.contract_address}
                symbol={r.contract_ticker_symbol}
                chainId={r.chainId}
                tokenId={r.contract_address}
              />
            );
          },
        },
    tickerCol,
    balanceCol,
    !mob && {
      width: 150,
      render: actionsRenderer,
    },
    ignoreCol,
  ].filter(c => !!c);

  const rows = Object.entries(tokens)
    .map(([, v], key) => ({
      key,
      ...v,
    }))
    .filter(v => {
      // hide tokens with 0 balance
      if (doubleBalance(v) < 1e-5) return false;

      // hide tokens with 0 value
      if (tokenValue({ record: v, tokenPrices }) < 1e-2) return false;

      // no filters applied
      if (textFilter.length === 0) return true;
      return textCompare(textFilter, v.contract_ticker_symbol) || textCompare(textFilter, v.contract_address);
    })
    .sort((b, a) => tokenValue({ record: a, tokenPrices }) - tokenValue({ record: b, tokenPrices }));

  const networks = Object.entries(Constants.CHAINS).map(([, v]) => v.label);

  return (
    <>
      <Diagonal theme={theme} />
      <Wrapper>
        <Card
          actions={lastUpdated && [<Footer key="lastUpdated">Last Updated: {lastUpdated.toLocaleTimeString()}</Footer>]}
          style={{ borderRadius: 20, border: 'none' }}
          bodyStyle={{ padding: 0 }}
        >
          <CardHeader theme={theme}>
            <div style={{ display: 'flex' }}>
              <CardTitle>Explorer</CardTitle>
              <CardActions>
                <Button
                  key="add"
                  type="primary"
                  style={{ marginRight: 10 }}
                  onClick={() => setAddVisible(true)}
                  icon={<PlusOutlined />}
                >
                  Add Wallet
                </Button>
                <Settings key="settings" />
              </CardActions>
            </div>
            {isStarted && <Total />}
          </CardHeader>
          {!isStarted && (
            <div style={{ padding: '0px 30px' }}>
              <h3>Welcome!</h3>
              <div style={{ fontSize: 16 }}>
                <p>
                  This is the first app in the Kai ecosystem. Use it to track your entire DeFi portfolio in one place,
                  using multiple wallets and networks including {networks.slice(0, -1).join(', ')} and{' '}
                  {networks.slice(-1)}.
                </p>
                <Row gutter={0}>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <Tile className="tiles-item">
                      <TileHeader>Secure</TileHeader>
                      <p>Just enter any public Wallet ID you want to track</p>
                    </Tile>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <Tile className="tiles-item">
                      <TileHeader>Magic</TileHeader>
                      <p>Prices are retrieved from CoinGecko and updated automagically</p>
                    </Tile>
                  </Col>
                  <Col xs={24} sm={24} md={24} lg={8}>
                    <Tile className="tiles-item">
                      <TileHeader>Coming Soon...</TileHeader>
                      <ul>
                        <li>Charts &amp; Analytics</li>
                        <li>Tx History</li>
                        <li>Positions</li>
                      </ul>
                    </Tile>
                  </Col>
                </Row>
              </div>
            </div>
          )}
          <Wallets>
            <WalletAdd addVisible={addVisible} setAddVisible={setAddVisible} />
            <Row gutter={[20, 20]} style={{ marginTop: '-35px' }}>
              {walletIds.map((walletId, index) => (
                <Col key={walletId} xs={24} sm={24} md={24} lg={8}>
                  <Wallet walletId={walletId} index={index} />
                </Col>
              ))}
            </Row>
          </Wallets>
          <Chains />
          <TabsWrapper>
            <Tabs
              centered
              tabBarStyle={{ flex: 1, justifyContent: 'center' }}
              style={{ width: '100%' }}
              onChange={t => {
                setNft(t === 'nfts');
              }}
            >
              <Tabs.TabPane tab="Tokens" key="tokens">
                {isStarted && (
                  <>
                    <Input
                      placeholder="Find a token by Name or Contract #..."
                      suffix={<SearchOutlined />}
                      style={{ borderRadius: '50px', margin: '20px', width: 'calc(100% - 40px)' }}
                      value={textFilter}
                      onChange={e => setTextFilter(e.target.value)}
                    />
                    <Table
                      showHeader={false}
                      compact
                      size="small"
                      pagination={false}
                      columns={columns}
                      dataSource={rows}
                      style={{
                        margin: '0px 10px',
                      }}
                      locale={{
                        emptyText: <div style={{ padding: 10 }}>-</div>,
                      }}
                      expandable={{
                        rowExpandable: r =>
                          ![Constants.COINS.ETHEREUM, Constants.COINS.BNB].find(c => c === r.contract_address),
                        expandedRowRender: r => {
                          return (
                            <div>
                              {!mob && <TokenChart tokenId={r.contract_address} chainId={r.chainId} />}
                              {mob && (
                                <div style={{ display: 'flex', justifyContent: 'center', padding: 10 }}>
                                  {actionsRenderer(r)}
                                </div>
                              )}
                              <Card
                                size="small"
                                headStyle={{ backgroundColor: '#555', color: 'white' }}
                                title="Wallets"
                                bodyStyle={{ padding: 0 }}
                              >
                                <div></div>
                                <Table
                                  size="small"
                                  pagination={false}
                                  showHeader={false}
                                  columns={[
                                    {
                                      dataIndex: 'walletId',
                                      width: 240,
                                      render: v => <WalletCol walletId={v} />,
                                    },
                                    balanceCol,
                                  ]}
                                  dataSource={Object.entries(r.wallets).map(([w, v]) => ({
                                    key: w,
                                    walletId: w,
                                    ...v,
                                  }))}
                                />
                              </Card>
                              <Transactions tokenId={r.contract_address} chainId={r.chainId} />
                            </div>
                          );
                        },
                      }}
                    />
                  </>
                )}
              </Tabs.TabPane>
              <Tabs.TabPane
                tab="NFTs"
                key="nfts"
                style={{
                  padding: '60px 60px 40px 60px',
                }}
              >
                <Nfts />
              </Tabs.TabPane>
            </Tabs>
          </TabsWrapper>
        </Card>
      </Wrapper>
      <div style={{ textAlign: 'center' }}>
        <Button onClick={() => setFeatureVisible(true)}>Feature Requests</Button>
        <Modal
          visible={featureVisible}
          onCancel={() => setFeatureVisible(false)}
          footer={null}
          bodyStyle={{ padding: 0 }}
        >
          <iframe
            title="Feature Requests"
            className="airtable-embed"
            src="https://airtable.com/embed/shrPFQjwq9uD0jCoS?backgroundColor=blue"
            frameBorder="0"
            onmousewheel=""
            width="100%"
            height="583"
          ></iframe>
        </Modal>
      </div>
    </>
  );
};

const toExport = () => {
  return (
    <WalletProvider>
      <Wally />
    </WalletProvider>
  );
};

export default toExport;
