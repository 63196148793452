const Constants = {
  CHAINS: {
    ERC: {
      id: 1,
      label: 'Ethereum',
      platform: 'ethereum',
      trustWallet: 'ethereum',
      color: '#21325B',
      rpc: 'https://mainnet.infura.io/v3/8ea03e6d745d46e5880d4dea2e2185d0',
    },
    BSC: {
      id: 56,
      label: 'Binance Smart Chain',
      platform: 'binance-smart-chain',
      trustWallet: 'smartchain',
      color: '#F0B90B',
      rpc: 'https://bsc-dataseed.binance.org/',
    },
    POLYGON: {
      id: 137,
      label: 'Polygon/Matic',
      platform: 'polygon-pos',
      color: '#5E35A5',
    },
    // AVALANCHE: {
    //   id: 43114,
    //   label: 'Avalanche',
    //   platform: 'avalanche',
    // },
    FANTOM: {
      id: 250,
      label: 'Fantom',
      platform: 'fantom',
      color: '#09236A',
    },
    RINKEBY: {
      id: 4,
      label: 'Rinkeby',
      platform: 'ethereum',
      color: '#555',
    },
  },
  COINS: {
    ETHEREUM: '0xeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee',
    BNB: '0xb8c77482e45f1f44de1745f52c74426c631bdd52',
  },
  THEMES: {
    BLUE: {
      PRIMARY: '#03045e',
    },
    RED: {
      PRIMARY: '#9d0208',
    },
    GREEN: {
      PRIMARY: '#2d6a4f',
    },
    YELLOW: {
      PRIMARY: '#F0B90B',
    },
  },
  MOONTIPLIERS: [1, 2, 5, 10, 100],
};

export default Constants;
