import Web3 from 'web3';
import StakeABI from './SmartContractAbi/stake.json';
import KainetABI from './SmartContractAbi/kainet.json';
import { message } from 'antd';

const bscRpc = 'https://bsc-dataseed.binance.org/';
export const bscExplorer = 'https://bscscan.com';

const kainetContract = '0x723b6795be37ad8a0376acfb50034fa21912b439';
const stakingContract = '0x38AfE11cEE9dB78ED1934f33EB6c4975438F3719';

export async function get_web3(provider) {
  const wallet = new Web3(provider);
  const bsc = new Web3(bscRpc);
  return { wallet, bsc };
}

export async function get_contracts(provider) {
  const web3 = new Web3(provider);
  return {
    staking: new web3.eth.Contract(StakeABI, stakingContract),
    kainet: new web3.eth.Contract(KainetABI, kainetContract),
  };
}

export const calculate_rewards = async ({ poolId, stakeAmount, provider }) => {
  if (!stakeAmount) return 0;
  const { staking } = await get_contracts(provider);
  return await staking.methods.calcStakeMaxReward(poolId, stakeAmount).call();
};

export const get_info = async (provider, account) => {
  try {
    const { staking, kainet } = await get_contracts(provider);

    const pools = await Promise.all(
      [0, 1, 2, 3].map(r => {
        return staking.methods
          .pools(r)
          .call()
          .then(p => {
            return {
              id: r,
              lockDays: p[0],
              rewardRate: p[1],
              isFlexible: p[2],
              totalStaked: p[3],
              totalRewardsReserved: p[4],
            };
          })
          .catch(e => {
            console.log(e);
          });
      }),
    );

    const stakeCount = parseInt(await staking.methods.stakeCount(account).call());

    const stakes = [];
    for (let s = 0; s < stakeCount; s++) {
      const stakeId = await staking.methods.getStakeID(account, s).call();
      const stake = await staking.methods.stakeInfo(account, stakeId).call();
      stakes.push({ stakeId, ...stake });
    }

    const decimals = await kainet.methods.decimals().call();
    const allowance = (await kainet.methods.allowance(account, stakingContract).call()) / 10 ** decimals;
    const balance = (await kainet.methods.balanceOf(account).call()) / 10 ** decimals;

    const info = {
      pools,
      decimals,
      stakeCount,
      stakes,
      allowance,
      balance,
      approve: async ({ onProcessing, onReceipt }) => {
        return kainet.methods
          .approve(stakingContract, window.BigInt(2 ** 250))
          .send({ from: account })
          .on('transactionHash', tx => onProcessing({ tx, base: 'https://bscscan.com' }))
          .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: 'https://bscscan.com' }))
          .on('error', async error => message.error('An error ocurred'));
      },
      stake: async ({ poolId, stakeAmount, onProcessing, onReceipt }) => {
        return staking.methods
          .beginStake(poolId, stakeAmount * 10 ** decimals)
          .send({ from: account })
          .on('transactionHash', tx => onProcessing({ tx, base: 'https://bscscan.com' }))
          .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: 'https://bscscan.com' }))
          .on('error', async error => message.error('An error ocurred'));
      },
      unstake: async ({ stakeId, onProcessing, onReceipt }) => {
        return staking.methods
          .endStake(stakeId)
          .send({ from: account })
          .on('transactionHash', tx => onProcessing({ tx, base: 'https://bscscan.com' }))
          .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: 'https://bscscan.com' }))
          .on('error', async error => message.error('An error ocurred'));
      },
    };

    return info;
  } catch (err) {
    return false;
  }
};
