import { Button, message, Modal, Spin, Table } from 'antd';
import { useContext, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import { ArrowDownOutlined } from '@ant-design/icons';

import { ethExplorer, bscExplorer, get_contracts } from 'components/sections/swap/AllFunctions/Contracts';
import Constants from 'components/sections/wallet/constants';
import kai from 'assets/images/kaiinu.png';
import { CONTRACT } from 'Config';
import Snack from './Snack';
import { WalletContext } from './Swap';

const Deposit = () => {
  const { account, library } = useWeb3React();
  const { chainId, info, refetchInfo, loadingInfo } = useContext(WalletContext);
  const [snackMessage, setSnackMessage] = useState(null);
  const [modal, setModal] = useState(false);
  const isEth = chainId === Constants.CHAINS.ERC.id;

  const onProcessing = ({ tx, base }) => {
    setSnackMessage({
      title: 'Processing',
      message: (
        <a target="_blank" href={`${base}/tx/${tx}`} rel="noreferrer">
          View Tx
        </a>
      ),
    });
  };

  const onReceipt = ({ tx, base }) => {
    setSnackMessage({
      title: 'Success!',
      message: <a href={`${base}/tx/${tx}`}>View Tx</a>,
    });

    setModal(true);
    refetchInfo();
  };

  const getMethods = async () => {
    const contracts = await get_contracts(library.provider);
    if (!contracts) return {};
    return {
      old: contracts[isEth ? 'eth_old' : 'bsc_old'].methods,
      swap: contracts[isEth ? 'eth_swap' : 'bsc_swap'].methods,
    };
  };

  async function handleWithdrawEth() {
    const { swap } = await getMethods();
    const available = await swap._prevAmt(account).call();
    const fee = await swap.fee().call();

    await swap
      .WithdrawPrevBal(available)
      .send({ from: account, value: fee })
      .on('transactionHash', tx => onProcessing({ tx, base: ethExplorer }))
      .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: ethExplorer }))
      .on('error', async error => message.error('An error ocurred'));
  }

  async function handleWithdrawBsc() {
    const { swap } = await getMethods();
    const available = await swap._prevBepAmt(account).call();

    await swap
      .OldBepToNewBep(available)
      .send({ from: account })
      .on('transactionHash', tx => onProcessing({ tx, base: bscExplorer }))
      .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base: bscExplorer }))
      .on('error', async error => message.error('An error ocurred'));
  }

  return (
    <div>
      <Modal visible={modal} footer={null} onCancel={() => setModal(false)} title="Success!">
        <div>
          <p>KAINET will have a total Supply of 1,000,000,000 and will have the same value as KAIINU at launch</p>
          <p>
            <b>Contract Adress: </b>
            {CONTRACT}
          </p>
        </div>
      </Modal>
      {snackMessage && <Snack content={snackMessage} />}
      <h3>Withdraw {loadingInfo && <Spin />}</h3>
      {info && (
        <>
          <p>Below are the balances available to withdraw as KAINET</p>
          <Table
            showHeader={false}
            pagination={false}
            dataSource={[
              {
                key: '1',
                network: 'bsc',
                name: 'KAIINU on BSC',
                balance: info.bsc_swap_available,
                icon: kai,
              },
              {
                key: '2',
                network: 'eth',
                name: 'KAIINU on ETH',
                balance: info.eth_swap_available,
                icon: kai,
              },
            ]}
            columns={[
              {
                dataIndex: 'name',
                render: (text, record) => (
                  <div style={{ display: 'flex' }}>
                    <img src={record.icon} alt="icon" style={{ height: 20, width: 20, marginRight: 10 }} />
                    {text}
                  </div>
                ),
              },
              {
                dataIndex: 'balance',
                render: v => <div style={{ textAlign: 'right' }}>{v.toLocaleString()}</div>,
              },
              {
                dataIndex: 'nothing',
                render: (v, r) => (
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      disabled={r.balance === 0}
                      onClick={() => {
                        r.network === 'bsc' ? handleWithdrawBsc() : handleWithdrawEth();
                      }}
                    >
                      Withdraw
                    </Button>
                  </div>
                ),
              },
            ]}
          />
          <div style={{ textAlign: 'center', marginBottom: 20, fontSize: '30px' }}>
            <ArrowDownOutlined />
          </div>
          <Table
            showHeader={false}
            pagination={false}
            dataSource={[
              {
                key: '1',
                network: 'bsc',
                name: 'KAINET Balance',
                balance: info.bsc_new_balance,
                icon: kai,
              },
            ]}
            columns={[
              {
                dataIndex: 'name',
                render: (text, record) => (
                  <div style={{ display: 'flex' }}>
                    <img src={record.icon} alt="icon" style={{ height: 20, width: 20, marginRight: 10 }} />
                    {text}
                  </div>
                ),
              },
              {
                dataIndex: 'balance',
                render: v => <div style={{ textAlign: 'right' }}>{v.toLocaleString()}</div>,
              },
            ]}
          />
        </>
      )}
    </div>
  );
};

export default Deposit;
