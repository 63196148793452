import React from 'react';
import styled from 'styled-components';
// import coinbase from '../../assets/images/logos/coinbase.png';
import cmc from '../../assets/images/logos/cmc.png';
import coingecko from '../../assets/images/logos/coingecko.png';
import nomics from '../../assets/images/logos/nomics.png';
import pancake from '../../assets/images/logos/pancake.png';
import bibox from '../../assets/images/logos/bibox.png';
import lbank from '../../assets/images/logos/lbank.png';
import { isMobile } from '../../utils/utils';
import { PANCAKE_SWAP, BIBOX, COINGECKO, NOMICS, COINMARKETCAP, LBANK } from '../../Config';

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 60px 0px;
`;

const LogoLink = styled.a`
  flex: 0 0 calc(100% / ${isMobile() ? 2 : 3});
`;

const Logo = styled.img`
  image-rendering: crisp-edges;
  padding: 20px 10px;
  margin: auto;
`;

export const logos = [
  {
    link: PANCAKE_SWAP,
    img: pancake,
  },
  {
    link: COINGECKO,
    img: coingecko,
  },
  {
    link: COINMARKETCAP,
    img: cmc,
  },
  {
    link: BIBOX,
    img: bibox,
  },
  {
    link: NOMICS,
    img: nomics,
  },
  {
    link: LBANK,
    img: lbank,
  },
];

const Logos = () => {
  const rendered = logos.map(l => (
    <LogoLink key={l.link} href={l.link} target="_blank">
      <Logo src={l.img} />
    </LogoLink>
  ));

  return (
    <section className="section has-top-divider has-bottom-divider">
      <div className="container">
        <Wrapper>{rendered}</Wrapper>
      </div>
    </section>
  );
};

export default Logos;
