import { CopyOutlined } from '@ant-design/icons';
import { message } from 'antd';
import React from 'react';
import styled from 'styled-components';
import { copy } from '../../../utils/utils';

const Copy = styled.span`
  cursor: pointer;
  margin-left: 5px;
`;

const Contract = ({ contract, style, full }) => {
  if (!contract) return null;

  return (
    <span style={{ fontSize: 14, color: '#777', ...style }}>
      {full ? contract : contract.slice(0, 4)}...{contract.slice(-4)}
      <Copy
        onClick={() => {
          copy(contract);
          message.info('Copied!');
        }}
      >
        <CopyOutlined />
      </Copy>
    </span>
  );
};

export default Contract;
