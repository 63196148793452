import { Card, Table, Tag } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { WalletContext } from './WalletContext';
import { fetchTransactions } from './api';
import { WalletCol } from './Wally';
import { formatCoins } from '../../../utils/utils';
import moment from 'moment';
import ScanLink from './ScanLink';

const Transactions = ({ tokenId, chainId }) => {
  const { walletIds } = useContext(WalletContext);
  const [transactions, setTransactions] = useState([]);

  const getTransactions = () => {
    walletIds.forEach(walletId => {
      fetchTransactions({ walletId, chainId, tokenId }).then(({ data, error }) => {
        setTransactions(prevTransactions =>
          [...prevTransactions, ...data.items].map((x, key) => ({ key, chainId, ...x })),
        );
      });
    });
  };

  // eslint-disable-next-line
  useEffect(getTransactions, []);

  return (
    <Card
      size="small"
      style={{ marginTop: 20 }}
      headStyle={{ backgroundColor: '#555', color: 'white' }}
      title="Transactions (last 100 of each wallet)"
    >
      <div></div>
      <Table
        pagination={transactions.length > 10}
        dataSource={transactions}
        columns={[
          {
            dataIndex: 'block_signed_at',
            render: v => <span style={{ fontSize: 14, color: '#777' }}>{moment(v).format('YYYY/MM/DD hh:mm:ss')}</span>,
            width: 100,
          },
          {
            dataIndex: 'transfers',
            title: 'Type',
            render: v => (v[0].transfer_type === 'IN' ? <Tag color="green">IN</Tag> : <Tag color="red">OUT</Tag>),
            width: 60,
          },
          {
            dataIndex: 'from_address',
            title: 'From',
            render: v => <WalletCol walletId={v} />,
          },
          {
            dataIndex: 'to_address',
            title: 'To',
            render: v => <WalletCol walletId={v} />,
          },
          {
            dataIndex: 'transfers',
            title: 'Amount',
            render: v => <span style={{ fontSize: 14, color: '#777' }}>{formatCoins(v[0].delta)}</span>,
          },
          {
            dataIndex: 'successful',
            render: v => (v ? <Tag color="green">Success</Tag> : <Tag color="red">Failed</Tag>),
          },
          {
            dataIndex: 'tx_hash',
            render: (v, r) => (
              <div style={{ display: 'flex' }}>
                <ScanLink chainId={r.chainId} transactionId={v} />
              </div>
            ),
          },
        ]}
      />
    </Card>
  );
};

export default Transactions;
