import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import { Button, Form, Input, message, Modal } from 'antd';
import { CREATE_COLLECTION, GET_COLLECTIONS, HARD_DELETE_COLLECTION } from 'query/Nft';
import { useState } from 'react';
import { mintCollection } from './Functions';
import { useSignature } from './hooks';

const CollectionCreator = () => {
  const sign = useSignature();
  const { account, library } = useWeb3React();
  const [file, setFile] = useState(null);
  const [visible, setVisible] = useState(false);

  const refetchQueries = [{ query: GET_COLLECTIONS, variables: { ownerAddress: account } }];

  const [createCollection] = useMutation(CREATE_COLLECTION, {
    refetchQueries,
  });

  const [hardDeleteCollection] = useMutation(HARD_DELETE_COLLECTION, {
    refetchQueries,
  });

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        icon={<PlusOutlined />}
        type="link"
        size="large"
      />
      <Modal
        style={{ top: 40 }}
        visible={visible}
        onCancel={() => setVisible(false)}
        title="Create Collection"
        footer={null}
      >
        <Form
          layout="vertical"
          onFinish={async v => {
            sign('collection')
              .then(signature => {
                const variables = {
                  name: v.name,
                  description: v.description,
                  file,
                };

                // Create on server - status pending
                return createCollection({
                  variables,
                  context: {
                    headers: {
                      signature,
                    },
                  },
                }).then(r => {
                  const { uri } = r.data.createCollection;

                  // Mint on chain to set address
                  mintCollection({
                    account,
                    provider: library.provider,
                    uri,
                    ...variables,
                  }).catch(e => {
                    // Failed, remove it
                    hardDeleteCollection({
                      variables: { uri },
                      context: {
                        headers: {
                          signature,
                        },
                      },
                    });
                  });
                });
              })
              .catch(e => {
                message.error('Failed to Sign');
                console.log(e);
              });
          }}
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="file">
            <Form.Item name="upload" label="Upload">
              <input
                type="file"
                required
                onChange={({
                  target: {
                    validity,
                    files: [f],
                  },
                }) => {
                  if (!validity.valid) {
                    message.info('Invalid file');
                    return;
                  }
                  setFile(f);
                }}
              />
            </Form.Item>
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Create
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default CollectionCreator;
