import { Button, Input, message, Spin, Table } from 'antd';
import { useContext, useState } from 'react';
import styled from 'styled-components';
import { useWeb3React } from '@web3-react/core';

import {
  bsca_address,
  bscExplorer,
  esca_address,
  ethExplorer,
  get_contracts,
} from 'components/sections/swap/AllFunctions/Contracts';
import kai from 'assets/images/kaiinu.png';
import Constants from 'components/sections/wallet/constants';
import { WalletContext } from './Swap';
import Snack from './Snack';

const MaxButton = styled.div`
  cursor: pointer;
`;

const Deposit = () => {
  const { chainId, account, library } = useWeb3React();
  const { info, refetchInfo, loadingInfo, setStage } = useContext(WalletContext);

  const [amount, setAmount] = useState(0);
  const [snackMessage, setSnackMessage] = useState(null);

  const isEth = chainId === Constants.CHAINS.ERC.id;
  const base = isEth ? ethExplorer : bscExplorer;
  const address = isEth ? esca_address : bsca_address;

  const onProcessing = ({ tx, base }) => {
    setSnackMessage({
      title: 'Processing',
      message: (
        <a target="_blank" href={`${base}/tx/${tx}`} rel="noreferrer">
          View Tx
        </a>
      ),
    });
  };

  const onReceipt = ({ tx, base }) => {
    setSnackMessage({
      title: 'Success!',
      message: <a href={`${base}/tx/${tx}`}>View Tx</a>,
    });

    setAmount(null);
    refetchInfo();
  };

  const getMethods = async () => {
    const contracts = await get_contracts(library.provider);
    if (!contracts) return {};
    return {
      old: contracts[isEth ? 'eth_old' : 'bsc_old'].methods,
      swap: contracts[isEth ? 'eth_swap' : 'bsc_swap'].methods,
    };
  };

  async function handleApprove() {
    const { old } = await getMethods();
    const balance = await old.balanceOf(account).call();

    await old
      .approve(address, window.BigInt(balance * 2))
      .send({ from: account })
      .on('transactionHash', tx => onProcessing({ tx, base }))
      .on('receipt', receipt => onReceipt({ tx: receipt.transactionHash, base }))
      .on('error', async error => message.error('An error ocurred'));
  }

  async function handleDeposit() {
    const { old, swap } = await getMethods();
    const balance = await old.balanceOf(account).call();
    const decimals = await old.decimals().call();
    const getAllowance = await old.allowance(account, address).call();

    setSnackMessage(null);
    if (Number(getAllowance) > Number(balance) && Number(balance) >= Number(amount * 10 ** decimals)) {
      await swap[isEth ? 'tokenDeposit' : 'OldBepSubmit'](window.BigInt(amount * 10 ** decimals))
        .send({ from: account })
        .on('transactionHash', tx => onProcessing({ tx, base }))
        .on('receipt', receipt => {
          onReceipt({ tx: receipt.transactionHash, base });
          setStage(2);
        })
        .on('error', async error => {
          message.error('An error ocurred');
          setSnackMessage(null);
        });
    } else if (getAllowance > balance) {
      message.error('Insufficient Balance.');
    } else {
      message.error('Insufficient Allowance');
    }
  }

  const approved =
    info &&
    ((isEth && info.eth_old_allowance >= info.eth_old_balance * 2) ||
      (!isEth && info.bsc_old_allowance >= info.bsc_old_balance * 2));

  return (
    <div>
      {snackMessage && <Snack content={snackMessage} />}
      <h3>Deposit {loadingInfo && <Spin />}</h3>
      {info && !approved && (
        <>
          <p>Before you can start the swap, you need to approve KAIINU</p>
          <Button onClick={handleApprove}>Approve</Button>
        </>
      )}
      {info && approved && (
        <>
          <p>Let's deposit your KAIINU so you can then withdraw it as KAINET. How much would you like to swap?</p>
          <Table
            showHeader={false}
            pagination={false}
            dataSource={[
              {
                key: '3',
                name: 'Available KAIINU',
                balance: isEth ? info.eth_old_balance : info.bsc_old_balance,
                icon: kai,
              },
            ]}
            columns={[
              {
                dataIndex: 'name',
                render: (text, record) => (
                  <div style={{ display: 'flex' }}>
                    <img src={record.icon} alt="icon" style={{ height: 20, width: 20, marginRight: 10 }} />
                    {text}
                  </div>
                ),
              },
              {
                dataIndex: 'balance',
                render: v => <div style={{ textAlign: 'right' }}>{v.toLocaleString()}</div>,
              },
            ]}
          />
          <Input
            onChange={e => setAmount(e.target.value)}
            addonAfter={
              <MaxButton
                onClick={async () => {
                  const { old } = await getMethods();
                  if (old) {
                    const old_decimals = await old.decimals().call();
                    const old_balance = (await old.balanceOf(account).call()) / 10 ** old_decimals;
                    setAmount(parseFloat(old_balance));
                  }
                }}
              >
                MAX
              </MaxButton>
            }
            value={amount}
            size="large"
          />
          <p style={{ fontSize: 14, marginTop: 5 }}>
            <b>Note:</b> if you see an error, please remove all decimals from the amount
          </p>
          <Button type="primary" size="large" style={{ marginTop: 20 }} block onClick={handleDeposit}>
            Deposit
          </Button>
        </>
      )}
    </div>
  );
};

export default Deposit;
