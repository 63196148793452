import React from 'react';
import classNames from 'classnames';
// import Script from 'react-load-script';
import { SectionSplitProps } from '../../utils/SectionProps';

import logo from '../../assets/yellow/gamepad.png';
import line from '../../assets/yellow/line.svg';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top',
    );

    return (
      <section {...props} className={outerClasses} id="tokenomics">
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h1 style={{ marginBottom: 10 }}>Roadmap</h1>
                  <img src={line} height={10} alt="Line" />
                  <br />
                  <ul>
                    <li>PUBLIC ALPHA NFT GAME</li>
                    <li>Wally v2.0 with new features</li>
                    <li>Wally App for Android/iOS</li>
                    <li>Announcing KAINET Vision</li>
                    <li>KAINET Vision App for Android/iOS</li>
                    <li>AMAs in international communities</li>
                    <li>KAINET NFTs</li>
                    <li>Start Marketing in Asia</li>
                    <li>Contests &amp; Giveaways</li>
                    <li>Updating Whitepaper</li>
                  </ul>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-scale-up',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <img src={logo} alt="Kainet" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
