const configurationData = {
  supported_resolutions: ['1', '5', '15', '30', '60', '1D', '1W', '1M'],
};

const gql = ({ path, network, baseCurrency, quoteCurrency }) => {
  return fetch(`http://localhost:3040/${path}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-API-KEY': 'BQYYx5LabyJJnqH4YFvnfDUxI2mRne1q',
    },
    mode: 'cors',
    body: JSON.stringify({
      network,
      baseCurrency,
      quoteCurrency,
    }),
  }).then(response => response.json());
};

const datafeed = ({ network, baseSymbol, quoteSymbol, quoteCurrency }) => ({
  onReady: callback => {
    setTimeout(() => callback(configurationData));
  },
  resolveSymbol: async (baseCurrency, onSymbolResolvedCallback, onResolveErrorCallback) => {
    const symbol = {
      ticker: baseCurrency,
      name: `${baseSymbol}/${quoteSymbol}`,
      session: '24x7',
      timezone: 'Etc/UTC',
      minmov: 1,
      pricescale: 10000000,
      has_intraday: true,
      intraday_multipliers: ['1', '5', '15', '30', '60'],
      has_empty_bars: true,
      has_weekly_and_monthly: false,
      supported_resolutions: configurationData.supported_resolutions,
      volume_precision: 1,
      data_status: 'streaming',
    };

    onSymbolResolvedCallback(symbol);
  },
  // This method is used by the charting library to get historical data for the symbol.
  getBars: async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
    try {
      if (resolution === '1D') {
        resolution = 1440;
      }

      const response = await gql({
        path: 'candles',
        network,
        baseCurrency: symbolInfo.ticker,
        quoteCurrency,
      });

      // console.log('response', response, quoteCurrency);

      const bars = response.data.ethereum.dexTrades.map(el => ({
        time: new Date(el.timeInterval.minute).getTime(), // date string in api response
        low: el.low,
        high: el.high,
        open: Number(el.open),
        close: Number(el.close),
        volume: el.volume,
      }));

      if (bars.length) {
        onHistoryCallback(bars, { noData: false });
      } else {
        onHistoryCallback(bars, { noData: true });
      }
    } catch (err) {
      console.log({ err });
    }
  },
  subscribeBars: (symbolInfo, resolution, onRealtimeCallback, subscribeID, onResetCacheNeededCallback) => {},
  unsubscribeBars: subscribeID => {},
});

export default datafeed;
