import React from 'react';
import { withRouter, Switch } from 'react-router-dom';
import 'antd/dist/antd.less';
import './App.css';

import AppRoute from './utils/AppRoute';

// Layouts
import LayoutDefault from './layouts/LayoutDefault';

// Views
import WalletProvider from 'components/connect/WalletProvider';
import Home from './views/Home';
import Wally from './components/sections/wallet/Wally';
import Chart from './components/sections/tv/TvChart';
import ApolloProvider from 'components/sections/vision/ApolloProvider';
import Vision from './components/sections/vision/Vision';
import Swap from './components/sections/swap/components/Swap';
import Stake from './components/sections/stake/components/Stake';

import Marketplace from 'components/sections/marketplace/Marketplace';
import Collection from 'components/sections/marketplace/Collection';
// import NftHome from 'components/sections/marketplace/Home';
import Privacy from 'components/sections/wallet/Privacy';

const App = () => {
  return (
    <WalletProvider>
      <ApolloProvider>
        <Switch>
          <AppRoute exact path="/" component={Home} layout={LayoutDefault} />
          <AppRoute exact path="/wally" component={Wally} layout={LayoutDefault} />
          <AppRoute exact path="/privacy" component={Privacy} layout={LayoutDefault} />
          <AppRoute exact path="/vision" component={Vision} layout={LayoutDefault} />
          <AppRoute exact path="/swap" component={Swap} layout={LayoutDefault} />
          <AppRoute exact path="/stake" component={Stake} layout={LayoutDefault} />

          <AppRoute exact path="/vision/token/:tokenId" component={Vision} layout={LayoutDefault} />
          {/* <AppRoute exact path="/vision" component={VisionMaintenance} layout={LayoutDefault} />
            <AppRoute exact path="/vision/token/:tokenId" component={VisionMaintenance} layout={LayoutDefault} /> */}
          <AppRoute exact path="/chart" component={Chart} layout={LayoutDefault} />

          <AppRoute
            exact
            path="/collections"
            component={Marketplace}
            layout={LayoutDefault}
            layoutProps={{ showConnect: true }}
          />
          {/* <AppRoute exact path="/nfts" component={NftHome} layout={LayoutDefault} layoutProps={{ showConnect: true }} /> */}
          <AppRoute
            exact
            path="/collections/:ownerAddress"
            component={Marketplace}
            layout={LayoutDefault}
            layoutProps={{ showConnect: true }}
          />
          <AppRoute
            exact
            path="/collection/:address"
            component={Collection}
            layout={LayoutDefault}
            layoutProps={{ showConnect: true }}
          />
        </Switch>
      </ApolloProvider>
    </WalletProvider>
  );
};

export default withRouter(props => <App {...props} />);
