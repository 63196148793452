import { useQuery } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import { Card, Col, Row, Tag } from 'antd';
import { GET_COLLECTIONS } from 'query/Nft';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';

import Contract from 'components/sections/wallet/Contract';
import CollectionCreator from './CollectionCreator';

const Header = styled.h3`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Collections = ({ ownerAddress }) => {
  const { data } = useQuery(GET_COLLECTIONS, {
    variables: { ownerAddress },
  });

  return (
    <div>
      <Header>
        My Collections <CollectionCreator />
      </Header>
      <Row gutter={20}>
        {data &&
          data.nftCollections.map(c => {
            return (
              <Col key={c.id} span={8}>
                <Card title={c.name} extra={c.address ? <Contract contract={c.address} /> : <Tag>Pending...</Tag>}>
                  {c.address && <Link to={`/collection/${c.address}`}>View</Link>}
                </Card>
              </Col>
            );
          })}
      </Row>
    </div>
  );
};

const PreCollections = () => {
  const { ownerAddress } = useParams();
  const { account } = useWeb3React();

  const address = ownerAddress || account;

  if (!address) return <div>Please Connect your Wallet</div>;

  return <Collections ownerAddress={address} />;
};

export default PreCollections;
