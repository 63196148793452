import React from 'react';
import styled from 'styled-components';
import { formatMoney } from '../../../utils/utils';

const MoneyStyle = styled.span`
  color: #2a9d8f;
  font-weight: bold;
`;

const Money = ({ amount, style, suffix }) => {
  return (
    <MoneyStyle style={style}>
      ${formatMoney(amount)}
      {suffix}
    </MoneyStyle>
  );
};

export default Money;
