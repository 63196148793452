import { gql } from '@apollo/client';

export const GET_COLLECTIONS = gql`
  query getCollections($ownerAddress: String) {
    nftCollections(ownerAddress: $ownerAddress) {
      id
      name
      s3Url
      address
      uri
    }
  }
`;

export const GET_COLLECTION = gql`
  query getCollection($address: String) {
    nftCollections(address: $address) {
      id
      name
      s3Url
      address
      uri
      nftCount
    }
  }
`;

export const CREATE_COLLECTION = gql`
  mutation createCollection($name: String!, $description: String, $file: Upload!) {
    createCollection(name: $name, description: $description, file: $file) {
      id
      name
      uri
    }
  }
`;

export const DELETE_COLLECTION = gql`
  mutation deleteCollection($id: ID!) {
    deleteCollection(id: $id)
  }
`;

export const HARD_DELETE_COLLECTION = gql`
  mutation hardDeleteCollection($uri: String!) {
    hardDeleteCollection(uri: $uri)
  }
`;

export const CREATE_NFT = gql`
  mutation createNft($collectionAddress: ID!, $name: String!, $description: String, $file: Upload!, $itemCopies: Int) {
    createNft(
      collectionAddress: $collectionAddress
      name: $name
      description: $description
      file: $file
      itemCopies: $itemCopies
    ) {
      id
      name
      s3Url
      address
      uri
    }
  }
`;

export const GET_NFTS = gql`
  query getNfts($collectionIds: [ID], $limit: Int, $offset: Int, $sort: String, $order: String) {
    nfts(collectionIds: $collectionIds, limit: $limit, offset: $offset, sort: $sort, order: $order) {
      id
      name
      s3Url
      address
      uri
    }
  }
`;
