import { gql } from '@apollo/client';

const funcs = {
  GET_ALL: gql`
    query GetPosts($type: PostType, $first: Int) {
      posts(orderBy: date_DESC, where: { type: $type }, first: $first) {
        id
        title
        date
        slug
        excerpt
        coverImage {
          id
          url
        }
      }
    }
  `,
  GET: gql`
    query GetPost($slug: String) {
      post(where: { slug: $slug }) {
        id
        title
        slug
        excerpt
        type
        coverImage {
          id
          url
        }
        content {
          html
        }
      }
    }
  `,
};

export default funcs;
