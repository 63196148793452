import React, { useState } from 'react';

import Snack from 'components/sections/swap/components/Snack';
import MarketplaceContainer from './MarketplaceContainer';
import { bscExplorer } from 'components/sections/swap/AllFunctions/Contracts';

export const MarketplaceContext = React.createContext();

const Marketplace = () => {
  const [snackMessage, setSnackMessage] = useState(null);

  const onProcessing = ({ tx }) => {
    setSnackMessage({
      title: 'Processing',
      message: (
        <a target="_blank" href={`${bscExplorer}/tx/${tx}`} rel="noreferrer">
          View Tx
        </a>
      ),
    });
  };

  const onReceipt = ({ tx }) => {
    setSnackMessage({
      title: 'Success!',
      message: <a href={`${bscExplorer}/tx/${tx}`}>View Tx</a>,
    });
  };

  return (
    <MarketplaceContext.Provider
      value={{
        onProcessing,
        onReceipt,
      }}
    >
      {snackMessage && <Snack content={snackMessage} />}
      <MarketplaceContainer />
    </MarketplaceContext.Provider>
  );
};

const Wrapper = () => {
  return <Marketplace />;
};

export default Wrapper;
