import React, { useEffect, useState } from 'react';

import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { useWeb3React } from '@web3-react/core';
import { createUploadLink } from 'apollo-upload-client';

const uploadLink = createUploadLink({
  uri: process.env.REACT_APP_ENV === 'production' ? 'https://kainet.onrender.com/q' : 'http://localhost:3040/q',
});

const Wrapper = ({ children }) => {
  const { account, library } = useWeb3React();
  const [client, setClient] = useState(null);

  useEffect(() => {
    if (account) {
      const authLink = setContext((_, { headers }) => {
        return {
          headers: {
            ...headers,
            account,
          },
        };
      });

      const preClient = new ApolloClient({
        cache: new InMemoryCache(),
        link: authLink.concat(uploadLink),
      });

      setClient(preClient);
    } else {
      const preClient = new ApolloClient({
        link: uploadLink,
        cache: new InMemoryCache(),
      });

      setClient(preClient);
    }
  }, [account, library]);

  return client && <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default Wrapper;
