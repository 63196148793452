import { PlusOutlined } from '@ant-design/icons';
import { useMutation } from '@apollo/client';
import { useWeb3React } from '@web3-react/core';
import { Button, Form, Input, InputNumber, message, Modal } from 'antd';
import { CREATE_NFT, GET_COLLECTION } from 'query/Nft';
import { useState } from 'react';
import { mintNft } from './Functions';
import { useSignature } from './hooks';

const NftCreator = ({ collectionAddress }) => {
  const sign = useSignature();

  const [file, setFile] = useState(null);
  const { account, library } = useWeb3React();
  const [visible, setVisible] = useState(false);

  const refetchQueries = [{ query: GET_COLLECTION, variables: { address: collectionAddress } }];

  const [createNft] = useMutation(CREATE_NFT, {
    refetchQueries,
  });

  return (
    <>
      <Button
        onClick={() => {
          setVisible(true);
        }}
        icon={<PlusOutlined />}
        type="link"
        size="large"
      />
      <Modal visible={visible} onCancel={() => setVisible(false)} title="Create new NFT" footer={null}>
        <Form
          layout="vertical"
          onFinish={async v =>
            sign('nft')
              .then(signature => {
                const variables = {
                  name: v.name,
                  description: v.description,
                  itemCopies: v.itemCopies,
                  file,
                  collectionAddress,
                };

                // Create on server - status pending
                createNft({
                  variables,
                  context: {
                    headers: {
                      signature,
                    },
                  },
                }).then(result => {
                  const { uri } = result.data.createNft;

                  // Mint on chain to set address
                  mintNft({
                    provider: library.provider,
                    account,
                    uri,
                    itemCopies: v.itemCopies,
                    collectionAddress,
                  }).catch(e => {
                    console.log('Failed');
                    console.log(e);
                  });
                });
              })
              .catch(e => {
                message.error('Failed to Sign');
                console.log(e);
              })
          }
        >
          <Form.Item name="name" label="Name" rules={[{ required: true }]}>
            <Input />
          </Form.Item>
          <Form.Item name="description" label="Description" rules={[{ required: true }]}>
            <Input.TextArea />
          </Form.Item>
          <Form.Item name="itemCopies" label="Supply" rules={[{ required: true }]}>
            <InputNumber min={1} />
          </Form.Item>
          <Form.Item name="file">
            <Form.Item name="upload" label="Upload">
              <input
                type="file"
                required
                onChange={({
                  target: {
                    validity,
                    files: [f],
                  },
                }) => {
                  if (!validity.valid) {
                    message.info('Invalid file');
                    return;
                  }
                  setFile(f);
                }}
              />
            </Form.Item>
          </Form.Item>
          <Button htmlType="submit" type="primary">
            Create
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default NftCreator;
