import React, { useState } from 'react';
import { useEffect } from 'react';
import TrustAssets from './TrustAssets.json';
import { getChain } from '../../../utils/utils';
import Constants from './constants';

import bsc from './images/logos/binance-smart-chain.png';
import eth from './images/logos/ethereum.png';

const TokenLogo = ({ chainId, tokenId, symbol }) => {
  const [logoUrl, setLogoUrl] = useState(null);

  useEffect(
    () => {
      if (tokenId === Constants.COINS.BNB || tokenId === Constants.COINS.ETHEREUM) return;

      const trustContract = TrustAssets.find(l => l.toLowerCase() === tokenId.slice(2).toLowerCase());
      const { trustWallet } = getChain(chainId);
      if (trustContract && trustWallet) {
        setLogoUrl(
          `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/${trustWallet}/assets/0x${trustContract}/logo.png`,
        );
      } else {
        setLogoUrl(`https://s3.us-east-2.amazonaws.com/nomics-api/static/images/currencies/${symbol}.jpg`);
      }
    },
    // eslint-disable-next-line
    [],
  );

  const imgProps = {
    style: { height: 40, width: 40 },
    onError: e => {
      setLogoUrl(null);
    },
  };

  if (tokenId === Constants.COINS.BNB) {
    imgProps.src = bsc;
  } else if (tokenId === Constants.COINS.ETHEREUM) {
    imgProps.src = eth;
  } else {
    imgProps.src = logoUrl;
  }

  return imgProps.src ? <img alt="logo" {...imgProps} /> : null;
};

export default TokenLogo;
