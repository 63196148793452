import React from 'react';
import HeroFull from '../components/sections/HeroFull';
import FeaturesTiles from '../components/sections/FeaturesTiles';
import FeaturesSplit from '../components/sections/FeaturesSplit';
import FeaturesSplit2 from '../components/sections/FeaturesSplit2';
import FeaturesSplit3 from '../components/sections/FeaturesSplit3';
import FeaturesSplit4 from '../components/sections/FeaturesSplit4';
import HowTo from '../components/sections/HowTo';
import Roadmap from '../components/sections/Roadmap';
import Logos from '../components/sections/Logos';
import Team from 'components/sections/Team';
import News from 'components/sections/News';

class Home extends React.Component {
  render() {
    return (
      <>
        {/* <HeroFull className="illustration-section-01" /> */}
        {/* <FeaturesSplit invertMobile imageFill topOuterDivider /> */}
        <FeaturesSplit2 invertMobile imageFill topOuterDivider />
        {/* <Logos /> */}
        {/* <FeaturesTiles />
        <FeaturesSplit3 invertMobile imageFill topOuterDivider />
        <FeaturesSplit4 invertMobile imageFill topOuterDivider />
        <Roadmap />
        <Team /> */}
        {/* <Testimonial topDivider className="illustration-section-03" /> */}
        {/* <Cta hasBgColor invertColor split /> */}
        {/* <HowTo /> */}
        {/* <News first={3} /> */}
      </>
    );
  }
}

export default Home;
