import { useLazyQuery, useQuery } from '@apollo/client';
import { Spin, Card, Tag, Row, Col, Pagination, Select, Form } from 'antd';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { GET_COLLECTIONS, GET_NFTS } from 'query/Nft';
import Contract from '../wallet/Contract';

const Cover = styled.div`
  background-image: url(${props => props.src});
  background-size: cover;
  background-position: center;
  height: 200px;
  width: 100%;
`;

const PAGE_SIZE = 6;

const Nft = ({ name, address, s3Url }) => {
  return (
    <Card cover={<Cover src={s3Url} />}>
      <Card.Meta
        title={
          <div style={{ display: 'flex', position: 'relative', alignItems: 'center' }}>
            <h3 style={{ flex: 1, padding: 0, margin: 0 }}>{name}</h3>
            {address ? (
              <Contract contract={address} />
            ) : (
              <Tag style={{ position: 'absolute', right: 0 }}>Pending...</Tag>
            )}
          </div>
        }
      />
    </Card>
  );
};

const Nfts = ({ collectionId, nftCount }) => {
  const [nfts, setNfts] = useState([]);
  const [page, setPage] = useState(1);
  const [filters, setFilters] = useState({});

  const { data: collectionData } = useQuery(GET_COLLECTIONS);

  const [getNfts, { data, loading }] = useLazyQuery(GET_NFTS, {
    onCompleted: r => {
      setNfts(r.nfts);
    },
  });

  useEffect(() => {
    getNfts({
      variables: {
        collectionIds: collectionId ? [collectionId] : null,
        ...filters,
        limit: PAGE_SIZE,
        offset: (page - 1) * PAGE_SIZE,
      },
    });
  }, [getNfts, collectionId, page, filters]);

  if (loading) return <Spin />;

  if (!data) return <div>Failed to fetch NFTs</div>;

  return (
    <div style={{ padding: 20 }}>
      <Row gutter={20}>
        <Col span={6}>
          <Card title={<b>Filters</b>}>
            <Form layout="vertical" onValuesChange={setFilters}>
              {!collectionId && (
                <Form.Item label="Collection" name="collectionIds">
                  <Select mode="multiple">
                    {collectionData?.nftCollections.map(c => (
                      <Select.Option key={c.id} value={c.id}>
                        {c.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              )}
            </Form>
          </Card>
        </Col>
        <Col span={18}>
          <Row gutter={20}>
            {data &&
              nfts.map(c => {
                return (
                  <Col key={c.id} span={8}>
                    <Nft {...c} />
                  </Col>
                );
              })}
          </Row>
          <div style={{ marginTop: 20 }}>
            <Pagination current={page} onChange={setPage} total={nftCount} showSizeChanger={false} />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Nfts;
