import styled from 'styled-components';
import { InfoCircleOutlined, SettingOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row, Switch, Table, Tooltip } from 'antd';
import React, { useState, useContext } from 'react';
import Constants from './constants';
import { WalletContext } from './WalletContext';
import Contract from './Contract';

const Dot = styled.div`
  background-color: ${props => props.color};
  border: 3px solid ${props => (props.selected ? 'blue' : 'transparent')};
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  cursor: pointer;
  height: 30px;
  width: 80px;
`;

const Header = styled.div`
  font-family: 'Lato', sans-serif;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
  background-color: #222;
  padding: 0px 10px;
`;

const Symbol = styled.div`
  font-weight: bold;
`;

const Address = styled.span`
  font-size: 12px;
  color: #444;
`;

const Settings = () => {
  const [settingsVisible, setSettingsVisible] = useState(false);
  const {
    chainKeys,
    toggleChainKey,
    setTheme,
    theme,
    ignoreTokens,
    removeIgnoreToken,
    ignoreCommonValues,
    setIgnoreCommonValues,
  } = useContext(WalletContext);

  return (
    <>
      <Button
        key="settings"
        type="link"
        style={{ color: 'white' }}
        onClick={() => setSettingsVisible(true)}
        icon={<SettingOutlined />}
      />
      <Modal visible={settingsVisible} onCancel={() => setSettingsVisible(false)} footer={null} title={<b>Settings</b>}>
        <Header>Theme</Header>
        <Row gutter={20} style={{ marginTop: 20 }}>
          {Object.entries(Constants.THEMES).map(([k, t]) => {
            return (
              <Col span={6} key={k} style={{ display: 'flex', justifyContent: 'center' }}>
                <Dot color={t.PRIMARY} selected={theme === k} onClick={() => setTheme(k)}>
                  {''}
                </Dot>
              </Col>
            );
          })}
        </Row>
        <br />
        <Header>Networks</Header>
        <Table
          pagination={false}
          size="small"
          showHeader={false}
          columns={[
            {
              render: r => {
                return (
                  <div style={{ display: 'flex' }}>
                    <img
                      alt={r.label}
                      src={require(`./images/logos/${r.platform}.png`).default}
                      style={{ marginRight: 10, height: 20, width: 20 }}
                    />{' '}
                    {r.label}
                  </div>
                );
              },
            },
            {
              render: r => <Switch checked={chainKeys.find(k => k === r.key)} onChange={v => toggleChainKey(r.key)} />,
              width: 50,
            },
          ]}
          dataSource={Object.entries(Constants.CHAINS).map(([k, v]) => ({
            key: k,
            ...v,
          }))}
        />
        <Header>Hidden Settings</Header>
        <Table
          pagination={false}
          size="small"
          showHeader={false}
          columns={[
            {
              dataIndex: 'name',
              render: (v, r) => (
                <div>
                  <span>{v} </span>
                  <Tooltip title={r.description}>
                    <InfoCircleOutlined />
                  </Tooltip>
                </div>
              ),
            },
            {
              render: r => <Switch checked={r.value} onChange={v => r.toggle(v)} />,
              width: 50,
            },
          ]}
          dataSource={[
            {
              name: 'Hide Known Scam Amounts',
              description: 'Ignore tokens with amounts that are commonly associated with unwanted airdrops',
              toggle: setIgnoreCommonValues,
              value: ignoreCommonValues,
            },
          ]}
        />
        <Header>Hidden Tokens</Header>
        <Table
          pagination={false}
          size="small"
          showHeader={false}
          columns={[
            {
              dataIndex: 'value',
              render: (v, r) => {
                const contract = <Contract contract={r.key} />;

                if (v === true) {
                  return contract;
                } else {
                  return (
                    <div>
                      <Symbol>{v.contract_name}</Symbol>
                      <Address>{contract}</Address>
                    </div>
                  );
                }
              },
            },
            {
              dataIndex: 'key',
              render: c => (
                <Button type="link" onClick={() => removeIgnoreToken(c)}>
                  Unhide
                </Button>
              ),
              width: 50,
            },
          ]}
          dataSource={Object.entries(ignoreTokens).map(([key, value]) => ({
            key,
            value,
          }))}
        />
      </Modal>
    </>
  );
};

export default Settings;
