import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import checkmark from '../../assets/images/checkmark.svg';
import crown from '../../assets/images/crown.svg';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      img: crown,
      title: 'Benefits',
      paragraph: '',
    };

    const items = [
      '2% rewards for holders',
      'Doxxed Team',
      'Giveaways & Contests',
      'Listed on 3 exchanges',
      'All LP Locked',
      'Audit in Progress',
    ].map(i => (
      <div key={i} className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
        <div className="tiles-item-inner">
          <div className="features-tiles-item-content">
            <img src={checkmark} style={{ height: 50, marginBottom: 20 }} alt="KAINET" />
            <h4 className="mt-0 mb-8">{i}</h4>
          </div>
        </div>
      </div>
    ));

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>{items}</div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
