import * as React from 'react';
import Datafeed from './datafeed';

// Use require - otherwise the build breaks
// const { widget } = require('./charting_library/charting_library');
import { widget } from '../../../charting_library';

function getLanguageFromURL() {
  const regex = new RegExp('[\\?&]lang=([^&#]*)');
  const results = regex.exec(window.location.search);
  return results === null ? null : decodeURIComponent(results[1].replace(/\+/g, ' '));
}

const calculateInitialResolution = (e, t, n, i) => {
  let a = '15';
  e && (a = '30');
  const o = i ? i.time : 1e3 * t,
    c = (Date.now() - o) / 36e5 / 24;
  return (
    c < 0.3 ? (a = '1') : c < 0.7 ? (a = '5') : c < 1 ? (a = '15') : n < 10 ? (a = '120') : n < 40 && (a = '60'), a
  );
};

class TvChart extends React.PureComponent {
  tvWidget = null;

  componentDidMount() {
    const tvWidget = new widget({
      symbol: this.props.baseCurrency,
      datafeed: Datafeed({
        network: this.props.network,
        baseSymbol: this.props.baseSymbol,
        quoteSymbol: this.props.quoteSymbol,
        quoteCurrency: this.props.quoteCurrency,
      }),
      interval: '1',
      container_id: 'tv_chart_container',
      library_path: `${
        process.env.REACT_APP_ENV === 'production' ? 'https://util.kainet.world' : process.env.PUBLIC_URL
      }/charting_library/`,
      theme: 'Dark',
      locale: getLanguageFromURL() || 'en',
      disabled_features: [
        'timeframes_toolbar',
        'show_interval_dialog_on_key_press',
        'symbol_search_hot_key',
        'study_dialog_search_control',
        'display_market_status',
        'header_compare',
        'header_symbol_search',
        'header_fullscreen_button',
        'control_bar',
        'edit_buttons_in_legend',
        'border_around_the_chart',
        'context_menus',
        'compare_symbol',
        'border_around_the_chart',
        'remove_library_container_border',
        'study_market_minimized',
      ],
      enabled_features: ['study_templates'],
      charts_storage_url: 'https://saveload.tradingview.com',
      charts_storage_api_version: '1.1',
      client_id: 'kainet.world',
      user_id: 'public_user_id',
      fullscreen: false,
      autosize: true,
      // studies_overrides: this.props.studiesOverrides,
    });

    this.tvWidget = tvWidget;

    tvWidget.onChartReady(() => {
      // tvWidget.headerReady().then(() => {
      //   const button = tvWidget.createButton();
      //   button.setAttribute('title', 'Click to show a notification popup');
      //   button.classList.add('apply-common-tooltip');
      //   button.addEventListener('click', () =>
      //     tvWidget.showNoticeDialog({
      //       title: 'Notification',
      //       body: 'TradingView Charting Library API works correctly',
      //       callback: () => {
      //         console.log('Noticed!');
      //       },
      //     }),
      //   );
      //   button.innerHTML = 'Check API';
      // });
    });
  }

  // componentWillUnmount() {
  //   if (this.tvWidget !== null) {
  //     this.tvWidget.remove();
  //     this.tvWidget = null;
  //   }
  // }

  render() {
    return <div id="tv_chart_container" style={{ height: 'calc(100vh - 400px)' }} />;
  }
}

export default TvChart;
