import { NFT_MARKET_CONTRACT } from 'Config';
import Web3 from 'web3';
import { abi, bytecode } from './artifacts/SpecialERC721.json';

const log = result => {
  result
    .on('transactionHash', t => {
      console.log('Transaction Hash:', t);
    })
    .on('receipt', r => {
      console.log('Receipt:', r);
    })
    .on('error', r => {
      console.log('Error:', r);
    });
};

export const mintCollection = ({ provider, account, uri, name }) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abi);
  const args = [uri, 1, name, name, NFT_MARKET_CONTRACT];
  const result = contract.deploy({ data: bytecode, arguments: args }).send({ from: account });
  log(result);
  return result;
};

export const mintNft = ({ provider, account, uri, itemCopies, collectionAddress }) => {
  const web3 = new Web3(provider);
  const contract = new web3.eth.Contract(abi, collectionAddress);
  const result = contract.methods.mint(uri, itemCopies).send({ from: account });
  log(result);
  return result;
};

// export const mintAndPutOnSale = async ({ provider, account, collectionAddress }) => {
//   const web3 = new Web3(provider);
//   const contract = new web3.eth.Contract(abi, collectionAddress);

//   if (data.saleData.type === 'auction') {
//     const { price, payableOption, duration } = data.saleData.fixedPrice;
//     const payableContract = new web3.eth.Contract(TokenAbi, payableOption);
//     const decimals = await payableContract.methods.decimals().call();
//     const newPrice = window.BigInt(price * 10 ** decimals);
//     const timestamp = await getDurationTimestamp(web3, duration);
//     const result = contract.methods
//       .mintWithFixedPrice(uri, itemCopies, payableOption, timestamp, newPrice)
//       .send({ from: accounts[0] });
//     result.on('transactionHash', console.log).on('receipt', console.log).on('error', console.log);
//     return result;
//   } else {
//     const { duration, payableOption } = data.saleData.auction;
//     const timestamp = await getDurationTimestamp(web3, duration);

//     const result = contract.methods
//       .mintWithAuction(uri, itemCopies, payableOption, timestamp)
//       .send({ from: accounts[0] });
//     result.on('transactionHash', console.log).on('receipt', console.log).on('error', console.log);
//     return result;
//   }
// };

// async function mintAndPutOnSale(wallet, data, image) {
//   const { name, description, externalLink, attributes, itemCopies, collection } = data;

//   const uri = await makeMetadata(name, image, description, externalLink, attributes);
//   if (uri) {
//     const web3 = new Web3(wallet.provider);
//     const accounts = wallet.accounts;
//     const contract = new web3.eth.Contract(abi, collection);
//     if (data.saleData.type === 'auction') {
//       const { price, payableOption, duration } = data.saleData.fixedPrice;
//       console.log(data.saleData);
//       const payableContract = new web3.eth.Contract(TokenAbi, payableOption);
//       const decimals = await payableContract.methods.decimals().call();
//       const newPrice = window.BigInt(price * 10 ** decimals);
//       const timestamp = await getDurationTimestamp(web3, duration);
//       const result = contract.methods
//         .mintWithFixedPrice(uri, itemCopies, payableOption, timestamp, newPrice)
//         .send({ from: accounts[0] });
//       result.on('transactionHash', console.log).on('receipt', console.log).on('error', console.log);
//       return result;
//     } else {
//       const { duration, payableOption } = data.saleData.auction;
//       const timestamp = await getDurationTimestamp(web3, duration);

//       const result = contract.methods
//         .mintWithAuction(uri, itemCopies, payableOption, timestamp)
//         .send({ from: accounts[0] });
//       result.on('transactionHash', console.log).on('receipt', console.log).on('error', console.log);
//       return result;
//     }
//   } else {
//     console.log('Unable to create uri.');
//     return 0;
//   }
// }
