export const TELEGRAM = 'https://t.me/KAINET_OFFICIAL';
//export const KAIINU_CONTRACT = '0xe5a09784b16e1065c37df14c6e2f06fdce317a1b';
export const CONTRACT = '0x723b6795be37ad8a0376acfb50034fa21912b439';
export const BURN_ADDRESS = '0x0000000000000000000000000000000000000001';
export const COVALENT_API_KEY = 'ckey_b3b9a08026b64ba6856b3c33203';
export const BSCSCAN_API_KEY = '3DXU2R53EWFZFRTD9XWMN1NI2EQP3RNP1Y';
export const PANCAKE_SWAP = `https://exchange.pancakeswap.finance/#/swap?outputCurrency=${CONTRACT}`;
export const UNISWAP = `https://app.uniswap.org/#/swap?outputCurrency=${CONTRACT}`;
export const TWITTER = 'https://twitter.com/Kainet_official';
export const INSTAGRAM = 'https://instagram.com/kainettoken';
export const FACEBOOK = 'https://www.facebook.com/groups/kainet';
export const REDDIT = 'https://www.reddit.com/r/KAINET/';
export const NOMICS = 'https://nomics.com/assets/kainet-kai-net';
export const YOUTUBE = 'https://www.youtube.com/channel/UC7b84bRIlqnfYSafVAVFFKg';
export const AUDIT_REPORT = 'https://kaiinu.s3.amazonaws.com/Kai+Inu+Full+Smart+Contract+Security+Audit.pdf';
export const BSCSCAN = `https://bscscan.com/token/${CONTRACT}`;
export const POOCOIN = `https://poocoin.app/tokens/${CONTRACT}`;
export const OPENSEA_API_KEY = 'c00f1f67451d46aba6690702e5204579';
export const OPENSEA_OWNER_CONTRACT = '0x9cbc1ec0fd4cd85f800bcc0e83d2af26740f23a6';
export const DEXTOOLS =
  'https://www.dextools.io/app/pancakeswap/pair-explorer/0x5aef2d7f6c2d32cb3603309382615248075aa595';
export const GRAPHCMS = 'https://api-us-east-1.graphcms.com/v2/ckoojrqft8jwm01xngueafkbx/master';
export const GRAPHCMS_AUTH_TOKEN =
  'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2MjEwMTE5MjMsImF1ZCI6WyJodHRwczovL2FwaS11cy1lYXN0LTEuZ3JhcGhjbXMuY29tL3YyL2Nrb29qcnFmdDhqd20wMXhuZ3VlYWZrYngvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiZTU3M2UzYjktZTI1Ni00MWI3LWFhYWEtODE4MzAyYjQyZDhkIiwianRpIjoiY2tvb2tuOHZzOXZzZjAxeG5leHljYzl3YiJ9.seDfdhE_q2VEDHXe2Ns0rsq6fa1bfYb-O_6U4NaLE8mMTBwso1HMVDH7GecEArXmA0XIIYmvTYfhb-5zAHxLGYsvluDesm-rYhJIhUwnr_OpsC1JesjGTC0Wkn-IQYJRzaoe2V7sI9IMeom3JuYQNUS4d-SZ3rQAOcXYHozqZQp47VXnRCQacN-ZhRVLGq-QbDlU4u2COeTZNQi3z6dW7ohLcPkwjcCtZ2tPYlxAMyltu0LxDgmVonvD_-OeKVdIghATTACNDhbK2glLGbryCEqv0FszWyWIyyoHaopBwj2plbEB8rWiHfzwh4bz4AiBuHOyy-qrE7DVRgbIcYHAOL6eoU0iWv8jfXu8w5ziDZN75elcvWS2_0Bvp1N54RH6v5puTPl6a0xRrQ2HkzS2MNkCkhVDbMsvtDmv1z0noYA1rOTsoPtlxIExI6In-Y3z9GCi4r6fsHEsdp_eFHLtJf4_E9cMiZOLfLUX7blWxLDVqnH9rbD7w9wB_fTau9Aa7ZVZPKd8X_ibee7lVZg2JfyxvTNJDdPbGq-aVPcCrEJBBozcAa4Duu1yfrlIneQzvPmAGM5viJniJ3istZTlm_Dj4BUKIBKSWD7-5ReP44o1PKulDzZjUdi9WfhTMnp2DFbAKF9corTKHMHRhNkLYh-5NHAQAMWbq6Lx_DQ-g1Y';
export const COINGECKO = 'https://www.coingecko.com/en/coins/kainet';
export const WHITEPAPER = 'https://kai-net.s3.amazonaws.com/Whitepaper.pdf';
export const COINMARKETCAP = 'https://coinmarketcap.com/currencies/kainet/';
export const BIBOX = 'https://www.bibox.com/en/exchange/basic/KAINET_USDT';
export const DISCORD = 'https://discord.gg/tgr3aFzfNz';
export const ONE_INCH = 'https://app.1inch.io/#/56/swap/BNB/KaiInu';
export const TOKPIE = 'https://tokpie.com/view_exchange/KaiInu-USDT/';
export const LBANK = 'https://www.lbank.info/exchange/kainet/usdt/#usd';
export const LOK = 'https://play.kainet.world';

// MARKET
export const NFT_MARKET_CONTRACT = '0xdc00DeA1B65Ed8e5bb838FC6bB4b737e921bD9df';
export const NFT_S3_BUCKET = 'https://kai-net.s3.amazonaws.com/nfts';

// Wally Blockers
export const ALLOWED_CONTRACTS = {
  // KaiInu
  [CONTRACT]: 1000000,
};

// 5 minutes allowed
// export const WALLY_FREE_TIME_SECS = 5 * 60 * 1000;
export const WALLY_FREE_TIME_SECS = 30 * 1000;
