import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import { Dropdown, Menu } from 'antd';

import { BSCSCAN, DEXTOOLS, LOK, PANCAKE_SWAP, POOCOIN, WHITEPAPER } from '../../Config';
import Logo from './partials/Logo';
import { ConnectButton } from 'components/connect/WalletProvider';

const propTypes = {
  active: PropTypes.bool,
  navPosition: PropTypes.string,
  hideNav: PropTypes.bool,
  hideSignin: PropTypes.bool,
  bottomOuterDivider: PropTypes.bool,
  bottomDivider: PropTypes.bool,
};

const defaultProps = {
  active: false,
  navPosition: '',
  hideNav: false,
  hideSignin: false,
  bottomOuterDivider: false,
  bottomDivider: false,
};

class Header extends React.Component {
  state = {
    isActive: false,
  };

  nav = React.createRef();
  hamburger = React.createRef();

  componentDidMount() {
    this.props.active && this.openMenu();
    document.addEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyPress);
    document.addEventListener('click', this.clickOutside);
    this.closeMenu();
  }

  openMenu = () => {
    document.body.classList.add('off-nav-is-active');
    this.nav.current.style.maxHeight = this.nav.current.scrollHeight + 'px';
    this.setState({ isActive: true });
  };

  closeMenu = () => {
    document.body.classList.remove('off-nav-is-active');
    this.nav.current && (this.nav.current.style.maxHeight = null);
    this.setState({ isActive: false });
  };

  keyPress = e => {
    this.state.isActive && e.keyCode === 27 && this.closeMenu();
  };

  clickOutside = e => {
    if (!this.nav.current) return;
    if (!this.state.isActive || this.nav.current.contains(e.target) || e.target === this.hamburger.current) return;
    this.closeMenu();
  };

  render() {
    const { className, active, navPosition, hideSignin, bottomOuterDivider, bottomDivider, ...props } = this.props;

    const hideNav = true;

    const classes = classNames('site-header', bottomOuterDivider && 'has-bottom-divider', className);

    const trackers = (
      <Menu>
        <Menu.Item>
          <a href={BSCSCAN}>Bsc Scan</a>
        </Menu.Item>
        <Menu.Item>
          <a href={DEXTOOLS}>DexTools</a>
        </Menu.Item>
        <Menu.Item>
          <a href={POOCOIN}>PooCoin</a>
        </Menu.Item>
      </Menu>
    );

    const utilities = (
      <Menu>
        <Menu.Item>
          <Link to="/wally">Wallet Explorer</Link>
        </Menu.Item>
        <Menu.Item>
          <Link to="/vision">Vision</Link>
        </Menu.Item>
        <Menu.Item>
          <a href={LOK} target="_blank" rel="noopener noreferrer">
            Land of Kai
          </a>
        </Menu.Item>
      </Menu>
    );

    return (
      <header {...props} className={classes}>
        <div className="container">
          <div className={classNames('site-header-inner', bottomDivider && 'has-bottom-divider')}>
            <Logo />
            {!hideNav && (
              <>
                <button
                  ref={this.hamburger}
                  className="header-nav-toggle"
                  onClick={this.state.isActive ? this.closeMenu : this.openMenu}
                >
                  <span className="screen-reader">Menu</span>
                  <span className="hamburger">
                    <span className="hamburger-inner"></span>
                  </span>
                </button>
                <nav ref={this.nav} className={classNames('header-nav', this.state.isActive && 'is-active')}>
                  <div className="header-nav-inner">
                    <ul className="list-reset header-nav-right">
                      <li style={{ fontSize: 14 }}>
                        <Dropdown overlay={trackers}>
                          <HashLink to="#">Trackers</HashLink>
                        </Dropdown>
                      </li>
                      <li style={{ fontSize: 14 }}>
                        <Dropdown overlay={utilities}>
                          <HashLink to="#">Utilities</HashLink>
                        </Dropdown>
                      </li>
                      <li style={{ fontSize: 14 }}>
                        <a href={WHITEPAPER}>Whitepaper</a>
                      </li>
                      <li style={{ fontSize: 14 }}>
                        <HashLink to="/#howto">How to Buy</HashLink>
                      </li>
                      <li style={{ fontSize: 14 }}>
                        <Link to="/news">News</Link>
                      </li>
                      <li>
                        <a href={PANCAKE_SWAP} className="button button-primary button-wide-mobile button-sm">
                          Buy Now
                        </a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </>
            )}
            {this.props.showConnect && <ConnectButton />}
          </div>
        </div>
      </header>
    );
  }
}

Header.propTypes = propTypes;
Header.defaultProps = defaultProps;

export default Header;
