import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  src: PropTypes.string.isRequired,
  width: PropTypes.number,
  height: PropTypes.number,
  alt: PropTypes.string,
};

const defaultProps = {
  src: undefined,
  width: undefined,
  height: undefined,
  alt: undefined,
};

class Image extends React.Component {
  state = {
    isLoaded: false,
  };

  image = React.createRef();
  placeholder = React.createRef();

  componentDidMount() {
    this.placeholder.current = document.createElement('img');
    this.handlePlaceholder(this.image.current);
  }

  componentWillUnmount() {
    this.placeholder.current.remove();
  }

  placeholderSrc = (w, h) => {
    return require('../sections/wallet/images/empty.png').default;
  };

  handlePlaceholder = img => {
    const width = this.props.width || 200;
    const height = this.props.height || 200;

    img.style.display = 'none';
    img.before(this.placeholder.current);
    this.placeholder.current.src = this.placeholderSrc(img.getAttribute('width') || 0, img.getAttribute('height') || 0);
    this.placeholder.current.width = width;
    this.placeholder.current.height = height;
    this.placeholder.current.style.width = `${width}px`;
    this.placeholder.current.style.height = `${height}px`;
    this.placeholder.current.style['z-index'] = 10;
    img.className && this.placeholder.current.classList.add(img.className);

    img.addEventListener('load', () => {
      this.placeholder.current.remove();
      img.style.display = '';
      this.setState({
        isLoaded: true,
      });
    });
  };

  render() {
    const { className, src, width, height, alt, ...props } = this.props;

    return <img {...props} ref={this.image} className={className} src={src} width={width} height={height} alt={alt} />;
  }
}

Image.propTypes = propTypes;
Image.defaultProps = defaultProps;

export default Image;
