import { Web3Provider } from '@ethersproject/providers';
import { Web3ReactProvider, useWeb3React } from '@web3-react/core';
import { Button, Modal } from 'antd';
import { useState } from 'react';
import { injected, walletConnect } from './connectors';
import walletConnectLogo from 'assets/images/logos/walletconnect.png';
import metaMaskLogo from 'assets/images/logos/metamask.png';

export const ConnectButton = () => {
  const [visible, setVisible] = useState(false);
  const context = useWeb3React();
  const { activate, deactivate, account } = context;

  return (
    <div>
      <Modal title="Connect Wallet" visible={!account && visible} onCancel={() => setVisible(false)} footer={null}>
        <div style={{ display: 'flex' }}>
          <Button
            style={{ flex: 1, marginRight: 10, height: 150 }}
            onClick={() => {
              activate(injected);
            }}
          >
            <img
              src={metaMaskLogo}
              alt="WalletConnect"
              style={{ height: 50, width: 50, margin: '0px auto 20px auto' }}
            />
            MetaMask
          </Button>
          <Button
            style={{ flex: 1, marginLeft: 10, height: 150 }}
            onClick={() => {
              activate(walletConnect);
            }}
          >
            <img
              src={walletConnectLogo}
              alt="WalletConnect"
              style={{ height: 50, width: 50, margin: '0px auto 20px auto' }}
            />
            <div>WalletConnect</div>
          </Button>
        </div>
      </Modal>
      <Button
        block
        size="large"
        onClick={() => {
          if (!account) {
            setVisible(true);
          } else {
            deactivate();
          }
        }}
        type="primary"
      >
        {account ? 'Disconnect' : 'Connect Wallet'}
      </Button>
    </div>
  );
};

const WalletProvider = ({ children }) => {
  return (
    <Web3ReactProvider
      getLibrary={p => {
        const library = new Web3Provider(p);
        library.pollingInterval = 12000;
        return library;
      }}
    >
      {children}
    </Web3ReactProvider>
  );
};

export default WalletProvider;
