import React, { useEffect } from 'react';
import Header from '../components/layout/Header';

const LayoutDefault = ({ children, showConnect }) => {
  useEffect(() => {
    // eslint-disable-next-line
    VANTA.FOG({
      el: '#root',
      mouseControls: true,
      touchControls: true,
      gyroControls: false,
      minHeight: 1.4 * window.innerHeight,
      minWidth: 200.0,
      highlightColor: 0x484848,
      midtoneColor: 0x0,
      lowlightColor: 0x4c4c55,
      baseColor: 0x0,
    });
  }, []);

  return (
    <>
      <Header navPosition="right" className="reveal-from-top" showConnect={showConnect} />
      <main className="site-content" id="site-content">
        {children}
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default LayoutDefault;
