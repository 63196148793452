import { useWeb3React } from '@web3-react/core';
import Web3 from 'web3';

export const useSignature = () => {
  const { account, library } = useWeb3React();

  return key => {
    const web3 = new Web3(library.provider);
    const msg = web3.utils.sha3(key);

    return web3.eth.sign(msg, account);
  };
};
