import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { isMobile } from '../../../utils/utils';

const propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    paragraph: PropTypes.string,
  }).isRequired,
  children: PropTypes.node,
  tag: PropTypes.oneOf(['h1', 'h2', 'h3']),
};

const defaultProps = {
  children: null,
  tag: 'h1',
};

class SectionHeader extends React.Component {
  render() {
    const { className, data, children, tag, ...props } = this.props;

    const classes = classNames('section-header', className);

    const Component = tag;

    return (
      <>
        {(data.title || data.paragraph) && (
          <div {...props} className={classes}>
            <div className="container-xs">
              {children}
              {data.img && <img src={data.img} alt={data.title} style={{ height: 120, width: 120 }} />}
              {data.pre && <h4 className="text-color-low">{data.pre}</h4>}
              {data.title && (
                <Component
                  className={classNames('mt-0', data.paragraph ? 'mb-20' : 'mb-0')}
                  style={{ fontSize: isMobile() ? 40 : 60, fontWeight: '900' }}
                >
                  {data.title}
                </Component>
              )}
              {data.paragraph && <p className="m-0">{data.paragraph}</p>}
            </div>
          </div>
        )}
      </>
    );
  }
}

SectionHeader.propTypes = propTypes;
SectionHeader.defaultProps = defaultProps;

export default SectionHeader;
