import { Alert, Col, Modal, Row } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import chunk from 'lodash/fp/chunk';
import styled from 'styled-components';
import { WalletContext } from './WalletContext';
import { isMobile } from '../../../utils/utils';
import { WalletCol } from './Wally';
import Image from '../../elements/Image';

const Wrap = styled.div`
  backface-visibility: hidden;
`;

const NftWrapper = styled.div`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: rotateX(5deg);
  transform-origin: bottom center;
  transition: all 0.2s ease-in-out;
  height: 100%;
  position: relative;
  z-index: 3;

  &:hover {
    transform: rotateX(0deg);
  }
`;

const Shadow = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0;
  display: block;
  width: 100%;
  height: 100%;
  box-shadow: -8px -5px 3px 2px rgb(0 0 0);
  z-index: 0;
  transform: skew(-75deg);
  height: 1px;
`;

const Depth = styled.div`
  background-color: #bbb;
  bottom: 20px;
  height: 100px;
  width: 100%;
  position: absolute;
  transform: rotateX(80deg);
  transform-origin: bottom;
  z-index: 1;
`;

const StyledRow = styled(Row)`
  margin-bottom: 40px;
  position: relative;
  perspective: 1000px;
  transform-style: preserve-3d;

  &:after {
    content: '';
    position: absolute;
    bottom: 0px;
    height: 20px;
    width: calc(100% - 40px);
    box-shadow: 20px 15px 25px black;
    transform: skew(235deg);
  }
`;

const Shelf = styled.div`
  height: 20px;
  background-color: #ddd;

  width: 100%;
  position: relative;
  z-index: 10;
`;

const Nft = ({ style, token_url, external_data, owner }) => {
  let { name, description, image } = {};

  if (external_data) {
    name = external_data.name;
    description = external_data.description;
    image = external_data.image;
  }

  const [media, setMedia] = useState(image);
  const [type, setType] = useState('image');
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (!image && token_url) {
      fetch(token_url, {
        mode: 'cors',
      })
        .then(r => r.json())
        .then(j => {
          if (j.animation_url) {
            setMedia(j.animation_url);
            setType('video');
          } else if (j.image_url) {
            setMedia(j.image_url);
          } else if (j.image) {
            setMedia(j.image);
          }
        });
    }
  }, [image, token_url]);

  let cover = null;
  const mediaStyle = { zIndex: 10 };

  if (type === 'video') {
    cover = (
      <video
        onMouseOver={e => e.target.setAttribute('controls', true)}
        onMouseOut={e => e.target.setAttribute('controls', false)}
        controlslist="nodownload"
        loop
        playsInline
        poster={image}
        src={media}
        style={mediaStyle}
      />
    );
  } else {
    cover = <Image key={name} alt={name} src={media} style={mediaStyle} />;
  }

  return (
    <>
      <Modal title={<b>{name || 'No name'}</b>} visible={visible} onCancel={() => setVisible(false)} footer={null}>
        {cover}
        <div style={{ padding: 20 }}>{description}</div>
        <WalletCol walletId={owner} />
      </Modal>
      {
        <NftWrapper onClick={() => setVisible(true)} style={style}>
          {cover}
          <Shadow />
        </NftWrapper>
      }
    </>
  );
};

const Nfts = () => {
  const { nfts } = useContext(WalletContext);

  if (nfts.length === 0) return <Alert message="You don't have any NFTs at the moment" />;

  const rows = chunk(isMobile() ? 1 : 3, nfts).map((r, c) => (
    <div key={c}>
      <StyledRow gutter={[40, 20]}>
        {r.map((nft, i) => {
          return (
            <Col
              key={i}
              xs={24}
              sm={24}
              md={24}
              lg={8}
              style={{
                position: 'relative',
                zIndex: 10,
              }}
            >
              <Nft
                {...nft}
                style={{
                  marginLeft: i === 0 && !isMobile() ? 20 : 0,
                  marginRight: i === 2 && !isMobile() ? 20 : 0,
                }}
              />
            </Col>
          );
        })}
        <Depth />
        <Shelf />
      </StyledRow>
    </div>
  ));

  return <Wrap>{rows}</Wrap>;
};

export default Nfts;
