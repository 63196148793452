import React from 'react';
import classNames from 'classnames';
import styled, { keyframes } from 'styled-components';
import { SectionProps } from '../../utils/SectionProps';

import { isMobile } from '../../utils/utils';
import { LOK, PANCAKE_SWAP } from '../../Config';
import hero from '../../assets/yellow/hero.jpg';
import { logos } from './Logos';

const propTypes = {
  ...SectionProps.types,
};

const defaultProps = {
  ...SectionProps.defaults,
};

const anim = keyframes`
  0%{
    transform: scale(0.95) translateY(0px) rotateZ(0deg) rotateX(-10deg);
    filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 1));
  }
  50%{
    transform: scale(1) translateY(-10px) rotateZ(0deg) rotateX(5deg);
    filter: drop-shadow(0px 40px 20px rgba(0, 0, 0, 1));
  }
  100%{
    transform: scale(.95) translateY(0px) rotateZ(0deg) rotateX(-10deg);
    filter: drop-shadow(0px 20px 20px rgba(0, 0, 0, 1));
  }
`;

const Breathe = styled.img`
  animation: ${anim} 4s ease-in-out infinite;
  height: 300px;
  filter: drop-shadow(0px 20px 0px rgba(0, 0, 0, 1));
`;

class HeroFull extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'hero section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'hero-inner section-inner pb-0',
      topDivider && 'has-top-divider',
      'has-bottom-divider',
    );

    return (
      <section
        {...props}
        className={outerClasses}
        style={{
          backgroundImage: `url(${hero})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
        }}
      >
        <div className="container-sm">
          {/* <Alert
            type="warning"
            message={
              <span>
                Our first app is here! Check out <Link to="/wally">Wallet Explorer</Link>
              </span>
            }
            style={{ marginTop: 20 }}
          ></Alert> */}
          <div className={innerClasses}>
            <div className="hero-content">
              <h3 className="mt-0 mb-20" style={{ fontSize: isMobile() ? 20 : 30 }}>
                KAINET PRESENTS
              </h3>
              <h1 className="mt-0 mb-20 primary" style={{ fontSize: isMobile() ? 50 : 70 }}>
                LAND OF KAI
              </h1>
              <div className="container-xs">
                <p className="m-0 mb-32">
                  Land of KAI is a 3D open world game with a strong story mode. Play the game to earn tokens while
                  challenging real opponents.
                </p>
                <div
                  className="reveal-from-top"
                  data-reveal-delay="450"
                  style={{
                    display: 'flex',
                    paddingBottom: 70,
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: isMobile() ? 'column' : 'row',
                  }}
                >
                  <a href={LOK} className="button button-primary button-wide-mobile" style={{ margin: 10 }}>
                    <span style={{ display: 'flex' }}>PLAY ALPHA</span>
                  </a>
                  <a href={PANCAKE_SWAP} className="button button-dark button-wide-mobile" style={{ margin: 10 }}>
                    <span style={{ display: 'flex' }}>BUY KAINET</span>
                  </a>
                </div>
                <div style={{ display: 'flex', flexWrap: 'wrap', paddingBottom: 40 }}>
                  {logos.map(l => {
                    return (
                      <a href={l.link} style={{ flex: '0 0 calc(100% / 3)' }}>
                        <img src={l.img} alt={l.alt} style={{ height: 30 }} />
                      </a>
                    );
                  })}
                </div>
                {/* <h1 className="mt-0 mb-12 reveal-from-top" data-reveal-delay="150">
                  Contract
                </h1>
                <p>
                  {CONTRACT}{' '}
                  <span
                    onClick={() => {
                      copy(CONTRACT);
                      message.info('Contract copied!');
                    }}
                    style={{ cursor: 'pointer' }}
                  >
                    <CopyOutlined />
                  </span>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

HeroFull.propTypes = propTypes;
HeroFull.defaultProps = defaultProps;

export default HeroFull;
