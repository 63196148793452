import { createChart } from 'lightweight-charts';
import { useRef, useEffect } from 'react';
import moment from 'moment';
import Colors from 'constants/Colors';

const Spark = ({ tokenId, data, width = 200, height = 40 }) => {
  const chartRef = useRef();

  useEffect(() => {
    if (!data) return;
    const { prices } = data;
    const lineData = prices.map((value, i) => {
      const time = moment().add(i, 'day').format('YYYY-MM-DD');
      return { time, value: parseFloat(value) };
    });

    if (lineData.length === 0) return;

    const chart = createChart(chartRef.current, {
      timeScale: {
        visible: false,
        fixLeftEdge: true,
        rightBarStaysOnScroll: true,
        borderVisible: false,
        fixRightEdge: true,
        fitContent: true,
      },
      layout: {
        backgroundColor: 'transparent',
      },
      grid: {
        vertLines: { visible: false },
        horzLines: { visible: false },
      },
      width,
      height,
      priceScale: {
        borderVisible: false,
        position: 'right',
        alignLabels: true,
        visible: false,
        color: 'red',
      },
      localization: {
        priceFormatter: p => '',
      },
    });

    const first = lineData[0];
    const last = lineData[lineData.length - 1];

    const lineSeries = chart.addLineSeries({
      color: first.value < last.value ? Colors.green7 : Colors.red7,
    });

    lineSeries.setData(lineData);
  }, []);

  if (!data) return null;

  return <div id={tokenId} ref={chartRef} />;
};

export default Spark;
