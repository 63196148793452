import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';

import lok from '../../assets/yellow/land.png';
import wallet from '../../assets/yellow/wally.png';
import vision from '../../assets/yellow/vision.png';
import { Link } from 'react-router-dom';
import { LOK } from 'Config';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class FeaturesTiles extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-tiles center-content',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'features-tiles-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'Utilities',
      paragraph: '',
    };

    const items = [
      {
        pre: '3D Open-World Game',
        label: <a href="https://play.kainet.world">Land of Kai (ALPHA)</a>,
        description: 'Land of Kai is a 3D open-world game where you can fight against other users and collect NFTs',
        img: lok,
        link: LOK,
      },
      {
        pre: 'Wallet Explorer',
        label: <Link to="/wally">WALLET EXPLORER</Link>,
        description:
          'Use it to track your entire DeFi portfolio in one place, supporting multiple networks. Available on AppStore and Google Play Store soon.',
        img: wallet,
        link: '/wally',
      },
      {
        pre: 'Kai Vision',
        label: <Link to="/vision">KAINET VISION</Link>,
        description:
          'KaiVision is a powerful crypto tracker supporting multiple chains. Track any token in a few seconds',
        img: vision,
        link: '/vision',
      },
    ].map((i, k) => (
      <div key={k} className="tiles-item">
        <div
          className="tiles-item-inner"
          style={{
            textAlign: 'left',
            borderRadius: 15,
            overflow: 'hidden',
            border: '1px solid #FFB713',
          }}
        >
          <div
            className="features-tiles-item-content"
            style={{
              height: '208px',
              backgroundImage: `url(${i.img})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
          />
          <div
            style={{
              padding: '0px 20px',
            }}
          >
            <h5 className="mb-0 mt-2" style={{ fontSize: 14, color: '#FFB713', textTransform: 'uppercase' }}>
              {i.pre}
            </h5>
            <h4 className="mt-0 mb-8">{i.label}</h4>
            <p>{i.description}</p>
          </div>
        </div>
      </div>
    ));

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            <div className={tilesClasses}>{items}</div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesTiles.propTypes = propTypes;
FeaturesTiles.defaultProps = defaultProps;

export default FeaturesTiles;
