import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Popover, Table, Tag } from 'antd';

import isNil from 'lodash/fp/isNil';

import { useLazyQuery } from '@apollo/client';
import { Link } from 'react-router-dom';

import Tokens from './queries/Tokens';
import Image from 'components/elements/Image';
import { formatNumberCompact, getMoment } from 'utils/utils';
// import Percentage from './Percentage';
import Sparkline from './Sparkline';
import { CheckOutlined } from '@ant-design/icons';
import Colors from 'constants/Colors';

import { isMobile } from '../../../utils/utils';

const Wrapper = styled.div`
  padding: 0px 40px;
`;

const PAGE_SIZE = 10;

const socialColumn = ({ dataIndex, icon, render }) => ({
  title: <span className="iconify" data-icon={icon} />,
  dataIndex,
  width: 80,
  render,
  sorter: true,
});

const Network = ({ network }) => {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Image src={network.logo || ''} alt="logo" height={20} width={20} />
      </div>
      <div style={{ marginLeft: 10 }}>{network.name}</div>
    </div>
  );
};

const CardValue = ({ label, value }) => {
  return (
    <div style={{ display: 'flex', fontSize: 16, padding: '5px 0px' }}>
      <b>{label}</b>
      <div style={{ textAlign: 'right', flex: 1 }}>{value}</div>
    </div>
  );
};

const TokenCard = ({ token }) => {
  const { id, name, logo, symbol, network, volume24hUsd, sparkline1d, renouncedOwnershipTxHash } = token;
  const renounced = !isNil(renouncedOwnershipTxHash);

  return (
    <Card
      title={
        <Link to={`/vision/token/${id}`}>
          <h3 style={{ margin: 0 }}>{symbol}</h3>
          {name}
        </Link>
      }
      extra={<Image src={logo || ''} alt="logo" height={50} width={50} />}
      style={{ marginBottom: 20, borderRadius: 10 }}
      bodyStyle={{ paddingTop: 0 }}
      actions={[
        <div style={{ marginLeft: 12 }}>
          <Network network={network} />
        </div>,
      ]}
    >
      {!isNil(volume24hUsd) && <CardValue label="Volume (24h)" value={formatNumberCompact(volume24hUsd)} />}
      <CardValue
        label="Renounced Ownership"
        value={<Tag color={renounced ? Colors.green8 : 'red'}>{renounced ? 'Yes' : 'No'}</Tag>}
      />
      <br />
      <Sparkline data={sparkline1d} tokenId={id} width={window.innerWidth - 70} />
    </Card>
  );
};

const TokenTable = () => {
  const [page, setPage] = useState(0);
  const [sort, setSort] = useState({
    sortBy: 'volume24hUsd',
    order: 'descend',
  });

  const [fetchTokens, { loading, data }] = useLazyQuery(Tokens.GET_TOKENS_FOR_LIST);

  useEffect(() => {
    const variables = {
      limit: PAGE_SIZE,
      offset: page * PAGE_SIZE,
      order: sort.order === 'descend' ? 'DESC' : 'ASC',
      sortBy: sort.sortBy,
    };

    fetchTokens({
      variables,
    });
  }, [page, fetchTokens, sort]);

  const tokens = data ? data.tokens : [];

  if (isMobile()) {
    return (
      <div style={{ padding: '0px 20px' }}>
        {tokens.map(t => (
          <TokenCard key={t.id} token={t} />
        ))}
      </div>
    );
  }

  return (
    <Wrapper>
      <Table
        rowKey="id"
        loading={loading}
        pagination={{
          total: tokens.length,
          simple: true,
        }}
        onChange={(pagination, filters, sorter) => {
          if (sorter?.field !== sort.sortBy || sorter?.order !== sort.order) {
            setSort({
              sortBy: sorter.field,
              order: sorter.order,
            });
          } else {
            setPage(pagination.current - 1);
          }
        }}
        columns={[
          {
            title: 'Name',
            dataIndex: 'name',
            render: (name, record) => {
              return (
                <Link to={`/vision/token/${record.id}`}>
                  <div style={{ display: 'flex' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <Image src={record.logo || ''} alt="logo" height={30} width={30} />
                    </div>
                    <div style={{ marginLeft: 10 }}>
                      <b>{record.symbol}</b>
                      <div>{name}</div>
                    </div>
                  </div>
                </Link>
              );
            },
            sorter: true,
          },
          {
            title: 'Network',
            dataIndex: 'network',
            render: (network, record) => <Network network={network} />,
            sorter: true,
            width: 250,
          },
          {
            title: 'Renounced Ownership',
            dataIndex: 'renouncedOwnershipTxHash',
            render: (renouncedOwnershipTxHash, record) => {
              return (
                renouncedOwnershipTxHash && (
                  <Popover
                    title="Renounced Ownership"
                    content={
                      <div>
                        <div>
                          <b>Date:</b> {getMoment(record.renouncedOwnershipTxDate).format('YYYY/MM/DD hh:mm:ss')}
                        </div>
                        <div>
                          <a href={record.renouncedOwnershipTxUrl} target="_blank" rel="noreferrer">
                            View Transaction
                          </a>
                        </div>
                      </div>
                    }
                  >
                    <Tag color={Colors.green8} icon={<CheckOutlined />}>
                      Yes
                    </Tag>
                  </Popover>
                )
              );
            },
            width: 150,
            sorter: true,
          },
          {
            title: 'Volume (24h)',
            dataIndex: 'volume24hUsd',
            render: (d, r) => {
              if (isNil(d)) return null;

              return (
                <div>
                  <div>${formatNumberCompact(d)}</div>
                  {/* <Percentage value={r.volumeChange24h} /> */}
                </div>
              );
            },
            width: 160,
            sorter: true,
            defaultSortOrder: 'descend',
          },
          // {
          //   title: 'Current Price',
          //   dataIndex: 'currentPrice',
          //   render: (d, r) => {
          //     if (isNil(d)) return null;

          //     return (
          //       <div>
          //         <div>${formatCoins(d)}</div>
          //         {/* <Percentage value={r.volumeChange24h} /> */}
          //       </div>
          //     );
          //   },
          //   width: 160,
          //   sorter: true,
          // },
          // {
          //   title: 'Market Cap',
          //   dataIndex: 'marketCap',
          //   render: (d, r) => {
          //     if (isNil(d)) return null;

          //     return (
          //       <div>
          //         <div>${formatNumberCompact(d)}</div>
          //         <Percentage value={r.marketCapChangePercentage24h} />
          //       </div>
          //     );
          //   },
          //   width: 150,
          //   sorter: true,
          // },
          {
            title: 'Last Day',
            dataIndex: 'sparkline1d',
            render: (d, r) => <Sparkline data={d} tokenId={r.id} />,
            width: 150,
          },
          socialColumn({
            dataIndex: 'telegramMembers',
            icon: 'fa-brands:telegram-plane',
            render: (t, r) => t && <a href={r.telegram}>{t.toLocaleString()}</a>,
          }),
          socialColumn({
            dataIndex: 'twitterFollowers',
            icon: 'fa-brands:twitter',
            render: (t, r) =>
              t && (
                <a rel="noopener noreferrer" target="blank" href={r.twitter}>
                  {t.toLocaleString()}
                </a>
              ),
          }),
          socialColumn({
            dataIndex: 'facebookLikes',
            icon: 'ant-design:like-outlined',
            render: (t, r) =>
              t && (
                <a rel="noopener noreferrer" target="blank" href={r.facebook}>
                  {t.toLocaleString()}
                </a>
              ),
          }),
          socialColumn({
            dataIndex: 'redditSubscribers',
            icon: 'fa-brands:reddit',
            render: (t, r) =>
              t && (
                <a rel="noopener noreferrer" target="blank" href={r.reddit}>
                  {t.toLocaleString()}
                </a>
              ),
          }),
        ]}
        dataSource={tokens}
      />
    </Wrapper>
  );
};

export default TokenTable;
