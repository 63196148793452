import { Alert, Button, Col, Row, Spin, Table, Select, Card, Popover } from 'antd';
import styled from 'styled-components';
import CountUp from 'react-countup';

import Tokens from 'components/sections/vision/queries/Tokens';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';
import { useState } from 'react';
import { Link } from 'react-router-dom';

import { formatCoins, formatMoney, formatNumberCompact, getMoment, isMobile } from 'utils/utils';

import { Action } from '../wallet/Wally';
import Percentage from './Percentage';
import Image from 'components/elements/Image';
import TvChart from 'components/sections/tv/TvChart';
import Contract from '../wallet/Contract';
import { InfoCircleOutlined } from '@ant-design/icons';

import bsc from 'assets/images/logos/bsc.png';
import eth from 'assets/images/logos/eth.png';

const Wrapper = styled.div`
  margin-bottom: 300px;
  padding: 0px ${isMobile() ? '20px' : '50px'};
`;

const Name = styled.span`
  font-weight: bold;
  font-size: 25px;
`;

const Symbol = styled.span`
  color: #8c8c8c;
  margin-left: 10px;
`;

const IconWrap = styled.span`
  font-size: 36px;
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
`;

const SocialIconWrap = styled.div`
  margin: 0px 10px;
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  min-width: 50px;
`;

const MetricValue = styled.div`
  display: flex;
  font-size: 20px;
  padding: 10px 0px;
`;

const Cell = styled.div`
  flex: 1;
  font-size: 20px;
`;

const DescriptionWrapper = styled.div`
  padding: 20px 0px;
  font-size: 16px;
  color: #8c8c8c;
`;

const PriceDisplay = styled.div`
  font-weight: bold;
  font-size: 40px;
  padding-right: 20px;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SocialIcon = ({ icon, count, link }) => {
  if (!link) return null;

  return (
    <SocialIconWrap>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <IconWrap>{icon}</IconWrap>
        <div style={{ fontSize: 17, textAlign: 'center' }}>
          <CountUp start={0} end={count} decimal="," duration={2} formattingFn={v => v.toLocaleString()} />
        </div>
      </a>
    </SocialIconWrap>
  );
};

const Description = ({ description }) => {
  const [expanded, setExpanded] = useState(false);

  if (!description) return null;

  const threshold = 300;
  const needsTruncate = description.length > threshold;
  const truncated =
    description.slice(0, expanded ? description.length : threshold) + (needsTruncate && !expanded ? '...' : '');

  return (
    <div style={{ marginBottom: 40 }}>
      <DescriptionWrapper dangerouslySetInnerHTML={{ __html: truncated }} />
      {needsTruncate && <Button onClick={() => setExpanded(!expanded)}>{expanded ? 'Hide' : 'Read More...'}</Button>}
    </div>
  );
};

const Metric = ({ prefix, label, value, percent, unit = '$' }) => {
  return (
    <MetricValue style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        {label} {prefix}
      </div>
      <div style={{ flex: 0, textAlign: 'right' }}>
        {unit}
        {formatNumberCompact(value)}
      </div>
      {percent && <Percentage value={percent * 100} style={{ fontSize: 20 }} />}
    </MetricValue>
  );
};

const getWeightedPrice = marketData => {
  const totalVolume = marketData.reduce((acc, cur) => acc + cur.volume24hUsd, 0);
  return marketData.reduce(
    (acc, { basePriceInUsd, volume24hUsd }) => acc + (basePriceInUsd * volume24hUsd) / totalVolume,
    0,
  );
};

const Price = ({ marketData }) => {
  // Weight by volume

  const prefix = (
    <Popover
      title="Pricing Sources"
      content={
        <Table
          size="small"
          dataSource={marketData}
          pagination={false}
          columns={[
            {
              title: 'Exchange',
              dataIndex: 'exchange',
              render: exchange => exchange.id,
            },
            {
              title: 'Price',
              dataIndex: 'basePriceInUsd',
              render: v => '$' + formatCoins(v),
            },
            {
              title: 'Volume (24hs)',
              dataIndex: 'volume24hUsd',
              render: v => '$' + formatMoney(v),
            },
          ]}
        />
      }
      placement="left"
    >
      <Button type="link">
        <InfoCircleOutlined style={{ fontSize: 22, marginRight: 0 }} />
      </Button>
    </Popover>
  );

  return (
    <PriceDisplay>
      {prefix}${formatCoins(getWeightedPrice(marketData))}
    </PriceDisplay>
  );
};

const Volume = ({ marketData }) => {
  const totalVolume = marketData.reduce((acc, cur) => acc + cur.volume24hUsd, 0);

  const prefix = (
    <Popover
      title="Volume Sources"
      placement="left"
      content={
        <Table
          size="small"
          dataSource={marketData}
          pagination={false}
          columns={[
            {
              title: 'Exchange',
              dataIndex: 'exchange',
              render: exchange => exchange.id,
            },
            {
              title: 'Pair',
              dataIndex: 'quote',
            },
            {
              title: 'Volume (24hs)',
              dataIndex: 'volume24hUsd',
              render: v => '$' + formatMoney(v),
            },
          ]}
        />
      }
    >
      <Button type="link">
        <InfoCircleOutlined style={{ fontSize: 22, marginRight: 0 }} />
      </Button>
    </Popover>
  );

  return <Metric label="Volume" prefix={prefix} value={totalVolume} unit="$" />;
};

const MarketCap = ({ marketData, burned, marketCap }) => {
  const prefix = (
    <Popover
      title="Market Cap"
      placement="left"
      content={
        <div>
          <div>
            <b>Supply</b>: {formatCoins(burned)}
          </div>
          <div>
            <b>Price</b>: ${formatCoins(getWeightedPrice(marketData))}
          </div>
        </div>
      }
    >
      <Button type="link">
        <InfoCircleOutlined style={{ fontSize: 22, marginRight: 0 }} />
      </Button>
    </Popover>
  );

  return <Metric label="Market Cap" prefix={prefix} value={marketCap} unit="$" />;
};

const Holders = ({ marketData }) => {
  const totalHolders = marketData.reduce((acc, cur) => acc + cur.holders, 0);

  const prefix = (
    <Popover
      title="Holders Sources"
      placement="left"
      content={
        <Table
          size="small"
          dataSource={marketData}
          pagination={false}
          columns={[
            {
              title: 'Exchange',
              dataIndex: 'exchange',
              render: exchange => exchange.id,
            },
            {
              title: 'Holders',
              dataIndex: 'holders',
              render: v => formatMoney(v),
            },
          ]}
        />
      }
    >
      <Button type="link">
        <InfoCircleOutlined style={{ fontSize: 22, marginRight: 0 }} />
      </Button>
    </Popover>
  );

  return <Metric label="Holders" prefix={prefix} value={totalHolders} unit />;
};

const VisionToken = () => {
  const mobile = isMobile();
  const { tokenId } = useParams();
  const [selectedMarketIndex, setSelectedMarketIndex] = useState(0);

  const { data, loading: loadingKainetData } = useQuery(Tokens.GET_TOKEN, {
    variables: {
      id: tokenId,
    },
  });

  if (loadingKainetData) return <Spin />;

  if (!data) return <Alert message="Failed to retrieve token" type="error" />;

  const { token } = data;

  if (!token) return <Alert message="Token not found" type="error" />;

  const {
    address,
    name,
    symbol,
    description,
    homepage,
    logo,
    telegram,
    telegramMembers,
    twitter,
    twitterFollowers,
    facebook,
    facebookLikes,
    reddit,
    redditSubscribers,
    marketData,
    network,
    burned,
    marketCap,
    renouncedOwnershipTxUrl,
    renouncedOwnershipTxDate,
  } = token;

  const market = (marketData || [])[selectedMarketIndex];

  return (
    <Wrapper>
      <Row gutter={20} style={{ marginBottom: 20 }}>
        <Col xs={24} sm={12} md={12}>
          <Link to="/vision">All Tokens</Link>
          <div style={{ display: 'flex' }}>
            <div style={{ marginRight: 20, marginTop: 10, flex: '0 0 45px' }}>
              <Image src={logo} alt="logo" height={45} width={45} />
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'self-start' }}>
              <div>
                <Name>{name}</Name>
                <Symbol>({symbol})</Symbol>
              </div>
              <div style={{ display: 'flex' }}>
                <img
                  src={network.id === 'bsc' ? bsc : eth}
                  alt="network"
                  style={{ height: 20, width: 20, marginRight: 10, marginTop: 2 }}
                />
                <Contract contract={token.address} style={{ fontSize: 16, padding: '0px 0px' }} />
              </div>
            </div>
            <Description description={description} />
          </div>
        </Col>
        {!mobile && (
          <Col xs={24} sm={12} md={12}>
            <Price marketData={marketData} />
          </Col>
        )}
      </Row>
      <Card
        style={{ marginTop: 20 }}
        headStyle={{
          borderBottom: '1px solid #444',
        }}
        title={
          <Select
            value={selectedMarketIndex}
            style={{ width: `calc(100% - ${mobile ? '0px' : '20px'})` }}
            options={marketData
              .filter(t => t.quote)
              .sort((a, b) => b.volume24hUsd - a.volume24hUsd)
              .map((t, i) => {
                return {
                  label: `${t.exchange.id}: ${symbol} / ${t.quote} - Vol. $${formatMoney(t.volume24hUsd)}`,
                  value: i,
                };
              })}
            onChange={setSelectedMarketIndex}
          />
        }
        extra={
          !mobile &&
          market && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Action title="DexGuru" logo="dexguru.png" url={`https://dex.guru/token/${address}`} />
              <Action title="PooCoin" logo="poocoin.png" url={`https://poocoin.app/tokens/${address}`} />
            </div>
          )
        }
      >
        <Row gutter={20}>
          <Col xs={24} sm={16} md={18}>
            {market && (
              <>
                <TvChart
                  key={selectedMarketIndex}
                  network={network.id}
                  exchangeName={market.exchange.id}
                  baseSymbol={symbol}
                  baseCurrency={address}
                  quoteSymbol={market.quote}
                  quoteCurrency={market.quoteAddress}
                />
              </>
            )}
          </Col>
          <Col xs={24} sm={8} md={6}>
            <Card
              style={{ marginTop: mobile ? 20 : 0 }}
              headStyle={{ textAlign: 'center' }}
              bodyStyle={{ paddingTop: 0 }}
              title={
                homepage ? (
                  <a href={homepage} target="_blank" rel="noopener noreferrer" style={{ fontSize: 18 }}>
                    {homepage}
                  </a>
                ) : (
                  <b>Community</b>
                )
              }
            >
              <div style={{ display: 'flex' }}>
                <SocialIcon
                  icon={<span className="iconify" data-icon="fa-brands:telegram-plane" />}
                  count={telegramMembers}
                  link={telegram}
                />
                <SocialIcon
                  icon={<span className="iconify" data-icon="fa-brands:twitter" />}
                  count={twitterFollowers}
                  link={twitter}
                />
                <SocialIcon
                  icon={<span className="iconify" data-icon="ant-design:like-outlined" />}
                  count={facebookLikes}
                  link={facebook}
                />
                <SocialIcon
                  icon={<span className="iconify" data-icon="fa-brands:reddit" />}
                  count={redditSubscribers}
                  link={reddit}
                />
              </div>
            </Card>
            <Card bodyStyle={{ padding: mobile ? 0 : 'auto' }}>
              {/* <MarketCap marketData={marketData} burned={burned} marketCap={marketCap} /> */}
              <Volume marketData={marketData} />
              {/* <Holders marketData={marketData} /> */}
              {renouncedOwnershipTxDate && (
                <MetricValue style={{ display: 'flex' }}>
                  <div style={{ flex: 1 }}>
                    <div>Renounced Ownership</div>
                    <a href={renouncedOwnershipTxUrl} target="_blank" rel="noreferrer">
                      View Transaction
                    </a>
                  </div>
                  <div style={{ flex: 0, textAlign: 'right' }}>
                    {getMoment(renouncedOwnershipTxDate).format('YYYY/MM/DD hh:mm:ss')}
                  </div>
                </MetricValue>
              )}
            </Card>
          </Col>
        </Row>
      </Card>
    </Wrapper>
  );
};

export default VisionToken;
