import React from 'react';
import Constants from './constants';
import { Action } from './Wally';

const ScanLink = ({ chainId, tokenId, transactionId }) => {
  const type = tokenId ? 'token' : 'tx';
  const id = tokenId || transactionId;

  if (chainId === Constants.CHAINS.BSC.id) {
    return <Action title="BSC Scan" logo="bscscan.png" url={`https://bscscan.com/${type}/${id}`} />;
  } else if (chainId === Constants.CHAINS.ERC.id) {
    return <Action title="Etherscan" logo="etherscan.png" url={`https://etherscan.com/${type}/${id}`} />;
  } else if (chainId === Constants.CHAINS.POLYGON.id) {
    return (
      <Action
        title="Polygon Explorer"
        logo="polygon-pos.png"
        url={`https://polygon-explorer-mainnet.chainstacklabs.com/${type === 'token' ? 'tokens' : type}/${id}`}
      />
    );
  } else if (chainId === Constants.CHAINS.FANTOM.id) {
    return <Action title="FTM Scan" logo="ftmscan.png" url={`https://ftmscan.com/${type}/${id}`} />;
  }

  return null;
};

export default ScanLink;
