import { Alert } from 'antd';

const Snack = ({ onClose, content: { title, message } }) => {
  return (
    <Alert
      style={{ position: 'fixed', right: 20, top: 20, zIndex: 9999, minWidth: '200px' }}
      message={title}
      description={message}
      closable
      onClose={onClose}
    />
  );
};

export default Snack;
