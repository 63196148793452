import React from 'react';
import classNames from 'classnames';
// import Script from 'react-load-script';
import { SectionSplitProps } from '../../utils/SectionProps';

import logo from '../../assets/yellow/samurai.png';
import line from '../../assets/yellow/line.svg';
import { WHITEPAPER } from 'Config';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top',
    );

    return (
      <section {...props} className={outerClasses} id="tokenomics">
        <div className="container">
          <div className={innerClasses}>
            <div className={splitClasses}>
              <div className="split-item">
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-scale-up',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <img src={logo} alt="Kainet" />
                </div>
                <div className="split-item-content center-content-mobile">
                  <h1 style={{ marginBottom: 10 }}>Why Kainet?</h1>
                  <img src={line} height={10} alt="Line" />
                  <br />
                  <p className="mb-5 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                    KAINET is building a metaverse where users can collect NFTs, buy land and perform real-life
                    activities like surfing and taking care of your pets.
                  </p>
                  <ul>
                    <li>2% rewards</li>
                    <li>Doxxed Team</li>
                    <li>4 exchanges</li>
                    <li>LP locked</li>
                    <li>Audited by CERTIK</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
