import Web3 from "web3";
import ERC20Abi from "./../SmartContractAbi/ethToken.json";
import BscSwapAbi from "./../SmartContractAbi/BepContract.json";
import EthSwapAbi from "./../SmartContractAbi/ethcontract.json";

export const eth_net_id = 1;
export const bsc_net_id = 56;
const ethereumRpc = "https://mainnet.infura.io/v3/8ea03e6d745d46e5880d4dea2e2185d0";
const bscRpc = "https://bsc-dataseed.binance.org/";

export const ethExplorer = "https://etherscan.io";
export const bscExplorer = "https://bscscan.com";

export const eotca_address = "0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b";
export const botca_address = "0xe5a09784b16E1065C37dF14c6e2f06fDcE317a1b";
export const bntca_address = "0xF061E00D3B99D7caf47A82d6F27570D3176f04C8";

export const esca_address = "0x530aAdf96D51213788dAd2df449FEb7481e49d38";
export const bsca_address = "0x6294f5b97edC474f31Fe1650dCCc91D9f00bFd5e";

export async function get_web3(provider) {
  const wallet = new Web3(provider);
  const infura = new Web3(ethereumRpc);
  const bsc = new Web3(bscRpc);
  return { wallet, infura, bsc };
}

export async function get_contracts(provider) {
    const web3 = new Web3(provider);
    const eth_old = new web3.eth.Contract(ERC20Abi, eotca_address);
    const bsc_old = new web3.eth.Contract(ERC20Abi, botca_address);
    const bsc_new = new web3.eth.Contract(ERC20Abi, bntca_address);
    
    const eth_swap = new web3.eth.Contract(EthSwapAbi, esca_address);
    const bsc_swap = new web3.eth.Contract(BscSwapAbi, bsca_address);
    return {eth_old, bsc_old, bsc_new, eth_swap, bsc_swap};
}

export async function get_info(provider, account) {
   try{
       const web3 = await get_web3(provider);

       const eth_old = new web3.infura.eth.Contract(ERC20Abi, eotca_address);
       const bsc_old = new web3.bsc.eth.Contract(ERC20Abi, botca_address);
       const bsc_new = new web3.bsc.eth.Contract(ERC20Abi, bntca_address);
       
       const bsc_swap = new web3.bsc.eth.Contract(BscSwapAbi, bsca_address);
       
       const bnb_balance = (await web3.bsc.eth.getBalance(account))/(10**18);
       const eth_balance = (await web3.infura.eth.getBalance(account))/(10**18);

       const eth_old_decimals = await eth_old.methods.decimals().call();
       const bsc_old_decimals = await bsc_old.methods.decimals().call();
       const bsc_new_decimals = await bsc_new.methods.decimals().call();
       
       const eth_old_balance = (await eth_old.methods.balanceOf(account).call())/(10**eth_old_decimals);
       const bsc_old_balance = (await bsc_old.methods.balanceOf(account).call())/(10**bsc_old_decimals);
       const bsc_new_balance = (await bsc_new.methods.balanceOf(account).call())/(10**bsc_new_decimals);


       const eth_old_allowance = (await eth_old.methods.allowance(account, esca_address).call())/(10**eth_old_decimals);
       const bsc_old_allowance = (await bsc_old.methods.allowance(account, bsca_address).call())/(10**bsc_old_decimals);

       const eth_swap_available = (await bsc_swap.methods._prevAmt(account).call())/(10**bsc_new_decimals)/(10**6);
       const bsc_swap_available = (await bsc_swap.methods._prevBepAmt(account).call())/(10**bsc_new_decimals)/(10**6);

       return {bnb_balance, eth_balance, eth_old_balance, bsc_old_balance, bsc_new_balance, eth_old_allowance, bsc_old_allowance, eth_swap_available, bsc_swap_available};

   }
   catch(err){
       return false;
   }


}
