import { BURN_ADDRESS, CONTRACT, BSCSCAN_API_KEY, COVALENT_API_KEY, OPENSEA_API_KEY } from '../../../Config';

const CG_BASE = 'https://api.coingecko.com/api/v3';
const COVALENT_BASE = 'https://api.covalenthq.com/v1';
const BSC_SCAN_BASE = 'https://api.bscscan.com/api';
const OPENSEA_BASE = 'https://api.opensea.io/api/v1';

export const fetchJson = (url, opts) => {
  try {
    return fetch(url, opts)
      .then(r => {
        if (r.status === 200) {
          return r.json();
        }

        console.error(r);
      })
      .catch(e => {
        console.log(e);
        return {};
      });
  } catch (e) {
    console.log(e);
    return {};
  }
};

export const fetchBalances = ({ walletId, chainId, nft }) => {
  return fetchJson(`${COVALENT_BASE}/${chainId}/address/${walletId}/balances_v2/?key=${COVALENT_API_KEY}&nft=${!!nft}`);
};

export const fetchTransactions = ({ walletId, chainId, tokenId }) => {
  return fetchJson(
    `${COVALENT_BASE}/${chainId}/address/${walletId}/transfers_v2/?contract-address=${tokenId}&key=${COVALENT_API_KEY}`,
  );
};

export const fetchTokenTransactions = ({ chainId, tokenId }) => {
  return fetchJson(`${COVALENT_BASE}/${chainId}/address/${tokenId}/transfers_v2/?key=${COVALENT_API_KEY}`);
};

export const fetchTokenPrices = ({ tokenIds, platform = 'ethereum', vs = 'usd' }) => {
  const merged = tokenIds.join('%2C');
  return fetchJson(`${CG_BASE}/simple/token_price/${platform}?contract_addresses=${merged}&vs_currencies=${vs}`).then(
    r => {
      const result = {};
      Object.entries(r).forEach(([k, v]) => {
        result[k] = v[vs];
      });
      return result;
    },
  );
};

export const fetchCoinPrices = () => {
  const vs = 'usd';
  const coinIds = ['ethereum', 'binancecoin'];
  const merged = coinIds.join('%2C');
  const url = `${CG_BASE}/simple/price?ids=${merged}&vs_currencies=${vs}`;

  return fetchJson(url).then(r => {
    const result = {};
    Object.entries(r).forEach(([k, v]) => {
      result[k] = v[vs];
    });
    return result;
  });
};

export const fetchScamCoins = () => {
  return fetchJson('https://wally-wallet.s3.amazonaws.com/coins.json').then(r => r.map(s => s.contract));
};

export const getFullTokenData = tokenId => {
  return fetchJson(
    `${CG_BASE}/coins/${tokenId}?tickers=true&market_data=true&community_data=true&developer_data=true&sparkline=true`,
  );
};

export const getMarketChart = ({ platform, tokenId } = { platform: 'binance-smart-chain', tokenId: CONTRACT }) => {
  return fetchJson(`${CG_BASE}/coins/${platform}/contract/${tokenId}/market_chart?vs_currency=usd&days=1`);
};

export const getCandleChart = ({ tokenId, days } = { tokenId: CONTRACT, days: 1 }) => {
  return fetchJson(`${CG_BASE}/coins/${tokenId}/ohlc?vs_currency=usd&days=${days}`);
};

export const getTokenData = ({ platform, tokenId } = { platform: 'binance-smart-chain', tokenId: CONTRACT }) => {
  return fetchJson(`${CG_BASE}/coins/${platform}/contract/${tokenId}`);
};

export const getTotalBurn = () =>
  getBscBalance({
    walletID: BURN_ADDRESS,
    contract: CONTRACT,
  }).then(r => r.result);

export const getBscBalance = ({ walletID, contract }) => {
  return getBalance({ base: BSC_SCAN_BASE, walletID, contract, apiKey: BSCSCAN_API_KEY });
};

const getBalance = ({ base, contract, walletID, apiKey }) => {
  const qs = [
    `module=account`,
    `action=tokenbalance`,
    `contractaddress=${contract}`,
    `address=${walletID}`,
    `apikey=${apiKey}`,
  ];
  const url = `${base}?${qs.join('&')}`;
  return fetch(url).then(r => r.json());
};

export const getOpenseaAssets = ({ ownerAddress, limit = 50, offset = 0 }) =>
  fetchJson(`${OPENSEA_BASE}/assets?owner=${ownerAddress}&offset=${offset}&limit=${limit}`, {
    method: 'GET',
    headers: {
      'X-API-KEY': OPENSEA_API_KEY,
    },
  }).then(r => r.assets);
