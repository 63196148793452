import styled from 'styled-components';
import { Route, Switch, useParams } from 'react-router';

import LayoutDefault from 'layouts/LayoutDefault';
import VisionToken from 'components/sections/vision/VisionToken';
import TokenTable from './TokenTable';

const Wrapper = styled.div``;

const Header = styled.div`
  height: ${props => (props.hidden ? 0 : 'auto')};
  transition: height 1s ease-in-out;
`;

const Vision = () => {
  const { tokenId } = useParams();

  // return (
  //   <Wrapper className="container-sm">
  //     <Alert message={<span style={{ color: 'black' }}>We're down for maintenance, will be back soon!</span>} />
  //   </Wrapper>
  // );

  return (
    <>
      <Wrapper className="container-sm">
        <Header hidden={tokenId}>
          <h3 style={{ marginTop: 0, textAlign: 'center' }}>Search &amp; find trusted Crypto</h3>
        </Header>
        {/* <TextSearch /> */}
      </Wrapper>
      <Switch>
        <Route exact path="/vision" component={TokenTable} layout={LayoutDefault} />
        <Route exact path="/vision/token/:tokenId" component={VisionToken} layout={LayoutDefault} />
      </Switch>
    </>
  );
};

export default Vision;
