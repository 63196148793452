import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';
import Colors from 'constants/Colors';
import isNil from 'lodash/isNil';
import { formatNumberCompact } from 'utils/utils';

const Percentage = ({ value, style }) => {
  if (isNil(value)) return null;

  const icon = value > 0 ? <ArrowUpOutlined /> : <ArrowDownOutlined />;
  let color = Colors.gray3;
  if (value > 0) {
    color = Colors.green6;
  } else if (value < 0) {
    color = Colors.red6;
  }

  return (
    <span style={{ fontSize: 12, color, ...style }}>
      {icon} {formatNumberCompact(value)}%
    </span>
  );
};

export default Percentage;
