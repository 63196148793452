import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';

import WalletProvider from 'components/connect/WalletProvider';
import { get_info } from 'components/sections/swap/AllFunctions/Contracts';
import SwapContainer from './SwapContainer';

export const WalletContext = React.createContext();

const Swap = () => {
  const { active, account, library, deactivate } = useWeb3React();
  const [stage, setStage] = useState(0);
  const [info, setInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);

  const disconnect = () => {
    deactivate();
    setInfo(null);
  };

  useEffect(() => {
    if (!active) return;
    setLoadingInfo(true);
    get_info(library.provider, account).then(i => {
      setInfo(i);
      setLoadingInfo(false);
    }); // eslint-disable-next-line
  }, [account]);

  return (
    <WalletContext.Provider
      value={{
        wallet: account,
        setStage,
        stage,
        disconnect,
        info,
        refetchInfo: () => get_info(library.provider, account).then(setInfo),
        clearInfo: () => setInfo(null),
        loadingInfo,
      }}
    >
      <SwapContainer />
    </WalletContext.Provider>
  );
};

const Wrapper = () => {
  return (
    <WalletProvider>
      <Swap />
    </WalletProvider>
  );
};

export default Wrapper;
