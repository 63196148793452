import { gql } from '@apollo/client';

const queries = {
  GET_TOKENS: gql`
    query getTokens($id: ID, $text: String) {
      tokens(id: $id, text: $text, allTokens: true) {
        id
        logo
        name
        symbol
      }
    }
  `,

  GET_TOKENS_FOR_LIST: gql`
    query getTokensForList($text: String, $limit: Int, $offset: Int, $sortBy: TokenSortBy, $order: Order) {
      tokens(text: $text, limit: $limit, offset: $offset, sortBy: $sortBy, order: $order) {
        id
        address
        symbol
        logo
        name

        network {
          id
          name
          logo
        }

        twitter
        twitterFollowers
        facebook
        facebookLikes
        telegram
        telegramMembers
        reddit
        redditSubscribers

        volume24hUsd
        sparkline1d
        # holders
        # marketCap
        # currentPrice

        renouncedOwnershipTxHash
        renouncedOwnershipTxUrl
        renouncedOwnershipTxDate
      }
    }
  `,

  GET_TOKEN: gql`
    query getToken($id: ID!) {
      token(id: $id) {
        address
        symbol
        logo
        name
        homepage

        network {
          id
          name
          logo
        }

        twitter
        twitterFollowers
        facebook
        facebookLikes
        telegram
        telegramMembers
        reddit
        redditSubscribers

        volume24hUsd
        minted
        burned

        # holders
        # marketCap
        # currentPrice
        sparkline1d

        renouncedOwnershipTxHash
        renouncedOwnershipTxDate

        marketData {
          exchange {
            id
          }

          holders
          basePriceInUsd
          volume24hUsd

          quote
          quoteAddress
        }
      }
    }
  `,

  GET_CANDLES: gql`
    query getCandles($id: ID!, $quoteContract: String, $minutes: Int) {
      token(id: $id) {
        id
        address
        network {
          id
        }
        candles(quoteContract: $quoteContract, minutes: $minutes)
      }
    }
  `,
};

export default queries;
