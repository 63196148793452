import { useQuery } from '@apollo/client';
import { GET_COLLECTION } from 'query/Nft';
import { Link, useParams } from 'react-router-dom';
import { Spin } from 'antd';
import styled from 'styled-components';
import NftCreator from './NftCreator';
import Nfts from './Nfts';

const Header = styled.div`
  font-size: 40px;
  font-weight: bold;
`;

const Collection = () => {
  const { address } = useParams();
  const { data, loading } = useQuery(GET_COLLECTION, {
    variables: { address },
  });

  if (loading) return <Spin />;

  const [collection] = data.nftCollections;

  if (!collection) return <div>Not Found</div>;

  const { name, nftCount } = collection;

  return (
    <section>
      <div className="container">
        <div>
          <div>
            <div style={{ textAlign: 'center' }}>
              <Link to="/collections">Collections</Link>
              <Header>
                {name}
                <NftCreator collectionAddress={address} />
              </Header>
            </div>
            <Nfts collectionId={collection.id} nftCount={nftCount} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Collection;
