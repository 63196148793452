import { Button, Card, Col, Row, Spin, Steps, Table } from 'antd';
import React, { useContext } from 'react';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';

import bsc from 'assets/images/logos/bsc.png';
import eth from 'assets/images/logos/eth.png';
import kai from 'assets/images/kaiinu.png';

import Deposit from './Deposit';
import Withdraw from './Withdraw';
import { WalletContext } from './Swap';

import { ConnectButton } from 'components/connect/WalletProvider';
import Contract from 'components/sections/wallet/Contract';
import constants from 'components/sections/wallet/constants';

const Stage = styled.span`
  color: white;
`;

const Wrapper = styled.div`
  background-color: #222;
  border-radius: 20px;
  padding: 40px;
  max-width: 600px;
  margin: 80px auto;
`;

const ConnectStage = () => {
  const { disconnect, setStage, info } = useContext(WalletContext);
  const { account, chainId } = useWeb3React();
  const isEth = chainId === constants.CHAINS.ERC.id;

  return (
    <div>
      {!account && (
        <>
          <h3>Let's get started!</h3>
          <p>Connect your wallet - make sure you're on the correct network</p>
          <ConnectButton />
        </>
      )}
      {!info && account && <Spin />}
      {account && info && (
        <>
          <p style={{ marginTop: 30 }}>Here are your balances:</p>
          <Table
            showHeader={false}
            pagination={false}
            dataSource={[
              {
                key: '1',
                name: 'ETH',
                balance: info.eth_balance,
                icon: eth,
              },
              {
                key: '2',
                name: 'BNB',
                balance: info.bnb_balance,
                icon: bsc,
              },
              {
                key: '3',
                name: `KAIINU on ${isEth ? 'Ethereum' : 'Binance Smart Chain'}`,
                balance: isEth ? info.eth_old_balance : info.bsc_old_balance,
                icon: kai,
              },
            ]}
            columns={[
              {
                dataIndex: 'name',
                render: (text, record) => (
                  <div style={{ display: 'flex' }}>
                    <img src={record.icon} alt="icon" style={{ height: 20, width: 20, marginRight: 10 }} />
                    {text}
                  </div>
                ),
              },
              {
                dataIndex: 'balance',
                render: v => <div style={{ textAlign: 'right' }}>{v.toLocaleString()}</div>,
              },
            ]}
          />
          <p>Does this look right?</p>
          <Row gutter={20}>
            <Col span={12}>
              <Button onClick={() => setStage(1)} block type="primary">
                Yes!
              </Button>
            </Col>
            <Col span={12}>
              <Button
                block
                onClick={() => {
                  disconnect();
                }}
              >
                No
              </Button>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default function SwapContainer() {
  const { account, chainId } = useWeb3React();
  const { disconnect, setStage, stage } = useContext(WalletContext);

  return (
    <Wrapper>
      <Steps size="small" current={stage} labelPlacement="vertical" style={{ marginBottom: 20 }}>
        <Steps.Step title={<Stage>Connect</Stage>} onClick={() => setStage(0)} />
        <Steps.Step title={<Stage>Deposit</Stage>} onClick={() => setStage(1)} />
        <Steps.Step title={<Stage>Withdraw</Stage>} onClick={() => setStage(2)} />
      </Steps>
      {account && (
        <Card
          size="small"
          title={
            <div>
              <div>
                <b>Wallet</b> <Contract contract={account} />
              </div>
              <div>
                <b>Network</b> {chainId === 1 ? 'Ethereum Mainnet' : 'Binance Smart Chain'}
              </div>
            </div>
          }
          extra={
            <Button
              onClick={() => {
                disconnect();
                setStage(0);
              }}
            >
              Disconnect
            </Button>
          }
          bodyStyle={{ display: 'none' }}
        />
      )}
      {stage === 0 && <ConnectStage />}
      {stage === 1 && <Deposit />}
      {stage === 2 && <Withdraw />}
    </Wrapper>
  );
}
