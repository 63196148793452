import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import { Line } from 'react-chartjs-2';
import styled from 'styled-components';

import { useInterval } from './hooks';
import { getMarketChart, getTokenData } from './api';
import { formatCoins, formatMoney, getChain, isMobile } from '../../../utils/utils';
import moment from 'moment';

const mobile = isMobile();

const Wrapper = styled.div`
  padding: 10px 20px;
`;

const Value = styled.div``;

const Outer = styled.div`
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: ${() => (mobile ? 'column' : 'row')};
`;

const GraphWrap = styled.div`
  padding: 20px 0px;
`;

const TokenChart = ({ tokenId, chainId }) => {
  const [price, setPrice] = useState(null);
  const [up, setUp] = useState(null);
  const [tokenData, setTokenData] = useState(null);
  const [marketChart, setMarketChart] = useState(null);
  const { platform } = getChain(chainId);

  const fetchTokenData = () => {
    getTokenData({ tokenId, platform }).then(d => {
      if (d.market_data) {
        const newPrice = d.market_data.current_price.usd;
        setPrice(newPrice);
        setUp(newPrice >= price);
      }

      setTokenData(d);
    });
  };

  // eslint-disable-next-line
  useEffect(fetchTokenData, []);

  useEffect(() => {
    getMarketChart({ tokenId, platform }).then(setMarketChart);
  }, [platform, tokenId]);

  useInterval(fetchTokenData, 1000 * 30);

  useInterval(() => {
    getMarketChart().then(setMarketChart);
  }, 1000 * 60 * 60);

  if (!tokenData || !marketChart)
    return (
      <div style={{ textAlign: 'center' }}>
        <Spin />
      </div>
    );
  const { market_data: m } = tokenData;

  const labels = [];
  const data = marketChart.prices
    ? marketChart.prices.map(p => {
        const x = moment(p[0]).format('hh:mm:ss');
        labels.push(x);
        return {
          x,
          y: p[1],
        };
      })
    : [];

  return (
    <Outer>
      <Wrapper style={{ zIndex: 1, width: '100%' }}>
        {m && (
          <Row gutter={20}>
            <Col span={12}>
              <Card title={<b>Current Price</b>} size="small">
                <Value style={{ color: up ? '#17c671' : '#c4183c' }}>${formatCoins(m.current_price.usd)}</Value>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={<b>24h Trading Volume</b>} size="small">
                <Value>${formatMoney(m.total_volume.usd)}</Value>
              </Card>
            </Col>
          </Row>
        )}
        {data.length > 0 && (
          <GraphWrap>
            <Line
              height={200}
              width={400}
              options={{
                plugins: {
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: tooltipItem => {
                        return formatCoins(tooltipItem.parsed.y);
                      },
                      afterLabel: () => null,
                    },
                  },
                },
                animation: false,
                elements: {
                  point: {
                    radius: 0,
                  },
                },
              }}
              data={{
                labels,
                datasets: [
                  {
                    data,
                    fill: false,
                    borderColor: '#ef233c',
                    borderWidth: 2,
                    tension: 0.1,
                  },
                ],
              }}
            />
          </GraphWrap>
        )}
      </Wrapper>
    </Outer>
  );
};

export default TokenChart;
