import React, { useContext } from 'react';
import styled from 'styled-components';
import max from 'lodash/fp/max';
import { WalletContext } from './WalletContext';
import Constants from './constants';
import { tokenValue } from '../../../utils/utils';
import { Col, Progress, Row } from 'antd';
import Money from './Money';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0px 20px 20px 20px;
`;

const Chains = () => {
  const { tokens, tokenPrices } = useContext(WalletContext);

  const balances = {};

  Object.entries(tokens).forEach(([, record]) => {
    balances[record.chainId] = (balances[record.chainId] || 0) + tokenValue({ record, tokenPrices });
  });

  const maxBalance = max(Object.entries(balances).map(([, v]) => v));

  const doms = Object.entries(Constants.CHAINS).map(([, c]) => {
    const chainBalance = balances[c.id];
    if (typeof chainBalance === 'undefined') return null;

    return (
      <Row style={{ padding: '2.5px 0px' }} key={c.id}>
        <Col xs={15} sm={15} md={15} lg={19}>
          <Progress
            trailColor="#ddd"
            strokeColor={c.color}
            percent={maxBalance ? (chainBalance / maxBalance) * 100 : 0}
            style={{ flex: '1' }}
            showInfo={false}
          />
        </Col>
        <Col xs={9} sm={9} md={9} lg={5}>
          <div style={{ justifyContent: 'flex-end', display: 'flex' }}>
            <img
              alt={c.label}
              src={require(`./images/logos/${c.platform}.png`).default}
              style={{ margin: '0px 5px', height: 20, width: 20 }}
            />
            <Money amount={chainBalance} />
          </div>
        </Col>
      </Row>
    );
  });

  return <Wrapper>{doms}</Wrapper>;
};

export default Chains;
