import React from 'react';
import classNames from 'classnames';
import { SectionTilesProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import sami from '../../assets/images/team/sami.jpg';
import nico from '../../assets/images/team/nico.jpg';
import benji from '../../assets/images/team/benji.jpg';
import terry from '../../assets/images/team/terry.jpg';
import baran from '../../assets/images/team/baran.jpg';
import vincent from '../../assets/images/team/vincent.jpg';
import Image from '../elements/Image';

const propTypes = {
  ...SectionTilesProps.types,
};

const defaultProps = {
  ...SectionTilesProps.defaults,
};

class Team extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      pushLeft,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'team section center-content',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'team-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const tilesClasses = classNames('tiles-wrap', pushLeft && 'push-left');

    const sectionHeader = {
      title: 'The KAINET Team',
    };

    const members = [
      {
        name: 'Benji',
        title: 'CEO',
        image: benji,
        link: 'https://www.linkedin.com/in/benjamin-jamali-2379791b2/',
      },
      {
        name: 'Nico',
        title: 'CTO',
        image: nico,
        link: 'https://www.linkedin.com/in/nico-teja-707a82224/',
      },
      {
        name: 'Vincent',
        title: 'COO',
        image: vincent,
        link: 'https://www.linkedin.com/in/vincent-herrera-santana-0713a8211',
      },
      {
        name: 'Sami',
        title: 'CFO',
        image: sami,
        link: 'https://www.linkedin.com/in/saman-jamali-51aaab225/',
      },
      {
        name: 'Baran',
        title: 'CMO',
        image: baran,
        link: 'https://www.linkedin.com/in/baran-bayram-0018b1226/',
      },
      {
        name: 'Terry',
        title: 'CGO',
        image: terry,
        link: 'https://www.linkedin.com/in/tmckainet',
      },
    ];

    return (
      <section {...props} className={outerClasses}>
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content reveal-from-bottom" />
            <div className={tilesClasses}>
              {members.map((m, i) => (
                <div key={i} className="tiles-item reveal-from-bottom" data-reveal-container=".tiles-wrap">
                  <div className="tiles-item-inner">
                    <div className="team-item-header">
                      <div className="team-item-image mb-24">
                        <a href={m.link} target="_blank" rel="noopener noreferrer">
                          <Image
                            src={m.image}
                            alt="Team member 01"
                            width={220}
                            height={220}
                            style={{ borderRadius: 20 }}
                          />
                        </a>
                      </div>
                    </div>
                    <div className="team-item-content">
                      <h5 className="team-item-name mt-0 mb-4">
                        <a href={m.link} target="_blank" rel="noopener noreferrer">
                          {m.name}
                        </a>
                      </h5>
                      <div className="team-item-role text-xs fw-500 mb-8">{m.title}</div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

Team.propTypes = propTypes;
Team.defaultProps = defaultProps;

export default Team;
