import React, { useCallback, useEffect, useState } from 'react';
import qs from 'qs';
import { useHistory, useLocation } from 'react-router';
import uniqBy from 'lodash/fp/uniqBy';
import uniq from 'lodash/fp/uniq';
import chunk from 'lodash/fp/chunk';

import { useInterval } from './hooks';
import { fetchBalances, fetchTokenPrices, fetchScamCoins, fetchCoinPrices } from './api';
import { storeObject, loadObject } from '../../../utils/utils';
import Constants from './constants';

const WalletContextClass = React.createContext({});

const COMMON_AIRDROP_AMOUNTS = ['2944857907000000000000000000'];

const ignoreTokenFilter =
  ({ scamCoins, ignoreTokens, ignoreCommonValues }) =>
  i => {
    if (ignoreCommonValues && COMMON_AIRDROP_AMOUNTS.includes(i.balance)) {
      return false;
    }

    return !ignoreTokens[i.contract_address] && !scamCoins.find(s => s === i.contract_address);
  };

const WalletProvider = ({ children }) => {
  // Get Wallet IDs from URL
  const location = useLocation();
  const history = useHistory();

  // Persisted data in URL
  const wParam = qs.parse((location.search || '?w=').slice(1)).w;
  const queryStringWallets = (wParam || '').split(',').filter(w => w.length > 0);
  const [walletIds, setWalletIds] = useState(queryStringWallets);

  // Persisted data in localStorage
  const [theme, setTheme] = useState(localStorage.getItem('theme') || 'BLUE');
  const [walletNames, setWalletNames] = useState(loadObject({ key: 'walletNames', defaultValue: {} }));
  const [chainKeys, setChainKeys] = useState(loadObject({ key: 'chainKeys', defaultValue: ['ERC', 'BSC'] }));
  const [ignoreTokens, setIgnoreTokens] = useState(loadObject({ key: 'ignoreTokens', defaultValue: {} }));
  const [ignoreCommonValues, setIgnoreCommonValues] = useState(
    loadObject({ key: 'ignoreCommonValues', defaultValue: false }),
  );

  const [nft, setNft] = useState(false);
  const [nfts, setNfts] = useState([]);
  const [scamCoins, setScamCoins] = useState([]);
  const [balances, setBalances] = useState({});
  const [coinPrices, setCoinPrices] = useState({});
  const [tokenPrices, setTokenPrices] = useState(null);

  const [tokens, setTokens] = useState({});
  const [moontipliers, setMoontipliers] = useState({});
  const [lastUpdated, setLastUpdated] = useState(null);

  // Wallet setup
  const addWalletId = newWalletId => {
    if (!walletIds.find(w => w === newWalletId)) {
      setWalletIds([...walletIds, newWalletId]);
      // eslint-disable-next-line
      gtag('event', 'added_wallet', {
        id: newWalletId,
      });
    }
  };

  const setWalletName = ({ walletId, name }) => {
    setWalletNames(prev => ({
      ...prev,
      [walletId]: name,
    }));
  };

  const setMoontiplier = ({ tokenId, value }) => {
    setMoontipliers(prev => ({
      ...prev,
      [tokenId]: value,
    }));
  };

  const deleteWalletId = walletId => {
    const newWalletIds = walletIds.filter(e => e !== walletId);
    setWalletIds(newWalletIds);

    // Remove inexisting WalletIds from balances if available
    if (balances) {
      const newBalances = {};
      newWalletIds.forEach(w => {
        newBalances[w] = balances[w];
      });
      setBalances(newBalances);
    }

    // Clear NFTs
    // setNfts([]);
  };

  const toggleChainKey = chainKey => {
    if (!chainKeys.find(c => c === chainKey)) {
      setChainKeys([...chainKeys, chainKey]);
    } else {
      setChainKeys(chainKeys.filter(c => c !== chainKey));
      // Clear
      setBalances({});
    }
  };

  // Fetchers
  const fetchAllPrices = useCallback(() => {
    const tokenIds = Object.keys(tokens);
    if (tokenIds.length === 0) {
      return;
    }

    const promises = chainKeys
      .map(k => {
        const chain = Constants.CHAINS[k];
        // Only filter for tokens in this chain
        const chainTokenIds = Object.keys(tokens).filter(k => tokens[k].chainId === chain.id);
        // Split into chunks
        const chunks = chunk(80, chainTokenIds);
        // Return 1 promise for each chunk
        return chunks.map(chunkTokenIds =>
          fetchTokenPrices({ tokenIds: chunkTokenIds, platform: chain.platform }).then(prices => {
            setTokenPrices(prevPrices => ({ ...prevPrices, ...prices }));
          }),
        );
      })
      .flat();

    Promise.all(promises).then(() => {
      setLastUpdated(new Date());
    });
  }, [chainKeys, tokens]);

  const fetchWalletBalance = ({ chainId, walletId }) => {
    return fetchBalances({
      chainId,
      walletId,
      nft: true,
    }).then(b => {
      if (b && b.data && b.data.items) {
        const newItems = [];
        let newNfts = [];

        b.data.items.forEach(i => {
          if (i.type === 'nft' && i.nft_data && i.nft_data.length > 0) {
            newNfts = [...newNfts, ...i.nft_data];
          } else {
            // Make the correction here
            if (i.contract_address === Constants.COINS.ETHEREUM && chainId === Constants.CHAINS.BSC.id) {
              i.contract_address = Constants.COINS.BNB;
            }

            newItems.push({
              ...i,
              chainId,
            });
          }
        });

        if (newNfts.length > 0) {
          setNfts(prevNfts => uniqBy(i => i.token_url, [...prevNfts, ...newNfts]));
        }

        setBalances(prevBalances => {
          const prevBalance = prevBalances[walletId] || [];

          const newBalance = uniqBy(
            i => i.contract_address,
            [...prevBalance, ...newItems].filter(ignoreTokenFilter({ scamCoins, ignoreTokens, ignoreCommonValues })),
          );

          return {
            ...prevBalances,
            [walletId]: newBalance,
          };
        });
      }
    });
  };

  const fetchAllScams = () => {
    fetchScamCoins().then(setScamCoins);
  };

  const fetchAllBalances = useCallback(() => {
    // Not ready
    if (walletIds.length === 0 || scamCoins.length === 0) {
      return;
    }

    walletIds.forEach(walletId =>
      chainKeys.map(k =>
        fetchWalletBalance({
          chainId: Constants.CHAINS[k].id,
          walletId,
          nft,
        }),
      ),
    );

    // eslint-disable-next-line
  }, [walletIds, scamCoins, chainKeys, ignoreTokens, ignoreCommonValues, nft]);

  const fetchAllCoinPrices = useCallback(() => {
    fetchCoinPrices().then(c => {
      setCoinPrices(c);
    });
  }, []);

  // Set up intervals
  useInterval(fetchAllScams, 60000);
  useInterval(fetchAllPrices, 5000);
  useInterval(fetchAllCoinPrices, 5000);
  useInterval(fetchAllBalances, 30000);

  // Set up triggers
  // eslint-disable-next-line
  useEffect(fetchAllPrices, [scamCoins, chainKeys]);
  // eslint-disable-next-line
  useEffect(fetchAllBalances, [scamCoins, chainKeys, nft]);

  const addIgnoreToken = ({ contract_address, contract_name, chainId }) => {
    const newIgnoreTokens = {
      ...ignoreTokens,
      [contract_address]: { contract_address, contract_name, chainId },
    };

    setIgnoreTokens(newIgnoreTokens);

    setBalances(prevBalances => {
      const newBalances = {};
      Object.entries(prevBalances).forEach(([walletId, tokens]) => {
        newBalances[walletId] = tokens.filter(
          ignoreTokenFilter({ scamCoins, ignoreTokens: newIgnoreTokens, ignoreCommonValues }),
        );
      });

      return newBalances;
    });
  };

  const removeIgnoreToken = contract_address => {
    setIgnoreTokens(prev => {
      const all = {};
      Object.keys(prev).forEach(k => {
        if (k !== contract_address) {
          all[k] = prev[k];
        }
      });

      return all;
    });
  };

  useEffect(() => {
    storeObject({ key: 'walletNames', obj: walletNames });
  }, [walletNames]);

  useEffect(() => {
    storeObject({ key: 'chainKeys', obj: chainKeys });
  }, [chainKeys]);

  useEffect(() => {
    storeObject({ key: 'ignoreTokens', obj: ignoreTokens });
  }, [ignoreTokens]);

  useEffect(() => {
    storeObject({ key: 'ignoreCommonValues', obj: ignoreCommonValues });
  }, [ignoreCommonValues]);

  // On start, fetch scams
  useEffect(fetchAllScams, []);

  useEffect(() => {
    const newTokens = {};

    const balanceEntries = Object.entries(balances || {});

    balanceEntries.forEach(([wid, items]) => {
      items.forEach(i => {
        // New token
        if (!newTokens[i.contract_address]) {
          newTokens[i.contract_address] = {
            ...i,
            balance: 0,
            wallets: {},
          };
        }

        // Add wallet
        newTokens[i.contract_address].wallets[wid] = i;
        // Add to balance
        newTokens[i.contract_address].balance += parseFloat(i.balance);
      });
    });

    setTokens(newTokens);
  }, [balances]);

  useEffect(() => {
    // Update URL
    history.push({
      pathname: '/wally',
      search: `w=${walletIds.join(',')}`,
    });
    // Save in local storage
    const currentUsedWallets = loadObject({ key: 'usedWallets', defaultValue: [] });
    storeObject({ key: 'usedWallets', obj: uniq([...walletIds, ...currentUsedWallets]) });
  }, [history, walletIds]);

  return (
    <WalletContextClass.Provider
      value={{
        theme,
        setTheme: n => {
          setTheme(n);
          localStorage.setItem('theme', n);
        },
        balances,
        tokenPrices: {
          ...tokenPrices,
          [Constants.COINS.ETHEREUM]: coinPrices.ethereum,
          [Constants.COINS.BNB]: coinPrices.binancecoin,
          moontipliers,
        },
        chainKeys,
        toggleChainKey,
        walletIds,
        addWalletId,
        deleteWalletId,
        tokens,
        ignoreTokens,
        addIgnoreToken,
        removeIgnoreToken,
        moontipliers,
        setMoontiplier,
        lastUpdated,
        walletNames,
        setWalletName,
        setNft,
        nfts,
        ignoreCommonValues,
        setIgnoreCommonValues,
      }}
    >
      {children}
    </WalletContextClass.Provider>
  );
};

export { WalletContextClass as WalletContext };

export default WalletProvider;
