import { useWeb3React } from '@web3-react/core';
import React, { useEffect, useState } from 'react';

import Snack from 'components/sections/swap/components/Snack';
import WalletProvider from 'components/connect/WalletProvider';
import { get_info } from 'components/sections/stake/Contracts';
import StakeContainer from './StakeContainer';
import { bscExplorer } from 'components/sections/swap/AllFunctions/Contracts';

export const WalletContext = React.createContext();

const Stake = () => {
  const { active, account, library, deactivate } = useWeb3React();
  const [info, setInfo] = useState(null);
  const [loadingInfo, setLoadingInfo] = useState(false);
  const [snackMessage, setSnackMessage] = useState(null);

  const onProcessing = ({ tx }) => {
    setSnackMessage({
      title: 'Processing',
      message: (
        <a target="_blank" href={`${bscExplorer}/tx/${tx}`} rel="noreferrer">
          View Tx
        </a>
      ),
    });
  };

  const onReceipt = ({ tx }) => {
    setSnackMessage({
      title: 'Success!',
      message: <a href={`${bscExplorer}/tx/${tx}`}>View Tx</a>,
    });

    get_info(library.provider, account).then(setInfo);
  };

  const disconnect = () => {
    deactivate();
    setInfo(null);
  };

  useEffect(() => {
    if (!active) return;
    setLoadingInfo(true);
    get_info(library.provider, account).then(i => {
      setInfo(i);
      setLoadingInfo(false);
    }); // eslint-disable-next-line
  }, [account]);

  return (
    <WalletContext.Provider
      value={{
        wallet: account,
        disconnect,
        info,
        refetchInfo: () => get_info(library.provider, account).then(setInfo),
        clearInfo: () => setInfo(null),
        loadingInfo,
        onProcessing,
        onReceipt,
      }}
    >
      {snackMessage && <Snack content={snackMessage} />}
      <StakeContainer />
    </WalletContext.Provider>
  );
};

const Wrapper = () => {
  return <Stake />;
};

export default Wrapper;
