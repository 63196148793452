import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import constants from 'components/sections/wallet/constants';

export const injected = new InjectedConnector({
  supportedChainIds: [constants.CHAINS.ERC.id, constants.CHAINS.BSC.id, constants.CHAINS.RINKEBY.id],
});

export const walletConnect = new WalletConnectConnector({
  rpc: {
    [constants.CHAINS.ERC.id]: constants.CHAINS.ERC.rpc,
    [constants.CHAINS.BSC.id]: constants.CHAINS.BSC.rpc,
  },
  qrcode: true,
});
