import { Alert, Button, Card, Col, Input, Modal, Popconfirm, Progress, Row, Spin, Table } from 'antd';
import React, { useContext, useEffect, useState } from 'react';
import { useWeb3React } from '@web3-react/core';
import styled from 'styled-components';
import moment from 'moment';
import { LoadingOutlined } from '@ant-design/icons';
import { ethers } from 'ethers';

import logo from '../../../../assets/images/kaiinu.png';
import { WalletContext } from './Stake';
import { isMobile } from 'utils/utils';
import { ConnectButton } from 'components/connect/WalletProvider';
import Contract from 'components/sections/wallet/Contract';
import Colors from 'constants/Colors';
import { calculate_rewards } from '../Contracts';

const Wrapper = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 80px auto;
`;

const formatNumber = (n, decimals) => {
  if (!n) return 0;
  return (+ethers.utils.formatUnits(`${n}`, decimals)).toFixed(2);
};

const TitleWithSub = ({ top, sub }) => {
  return (
    <div>
      <div>{top}</div>
      <div style={{ color: Colors.gray6, fontSize: 14 }}>{sub}</div>
    </div>
  );
};

const Stakes = () => {
  const { info, onProcessing, onReceipt } = useContext(WalletContext);
  const [createVisible, setCreateVisible] = useState(false);

  if (!info) return <Spin />;

  const { stakeCount, stakes, unstake, decimals, pools } = info;

  const mobile = isMobile();

  let columns = [];

  const getEarned = r => {
    const available = r.isMature || r.poolID === '0';
    const days = moment(r.maturityTimestamp * 1000).diff(moment(), 'days');
    const maturity = moment(r.maturityTimestamp * 1000).fromNow();
    const availableOn = days === 0 ? maturity : `in ${days} days`;

    const curTimestamp = new Date().getTime() / 1000;
    const diff1 = parseFloat(curTimestamp) - parseFloat(r.startTimestamp);
    const diff2 = parseFloat(r.maturityTimestamp) - parseFloat(r.startTimestamp);
    let earned = ethers.BigNumber.from(r.unusedReservedReward).mul(diff1.toFixed(0)).div(diff2.toFixed(0));
    let of = r.unusedReservedReward;

    if (r.isMature) {
      earned = r.withdrawableReward;
      of = earned;
    }

    return (
      <TitleWithSub
        top={
          <div>
            {formatNumber(earned, decimals)} of {formatNumber(of, decimals)}
            <Progress percent={(100 * earned) / of} showInfo={false} size="small" />
          </div>
        }
        sub={!available && <div>Available {availableOn}</div>}
      />
    );
  };

  if (mobile) {
    columns = [
      {
        title: 'KAINET Staked',
        dataIndex: 'amount',
        render: v => v / 10 ** decimals,
      },
      {
        title: 'KAINET Earned',
        dataIndex: 'withdrawableReward',
        render: (v, r) => <TitleWithSub top={getEarned(r)} sub={`APR ${r.rewardRate}%}`} />,
      },
      {
        dataIndex: 'stakeId',
        render: (s, r) => {
          if (!r.isActive) {
            return <Button disabled>Inactive</Button>;
          }

          return (
            <Button
              style={{ minWidth: '100px' }}
              block
              onClick={() => {
                unstake({
                  stakeId: s,
                  onProcessing,
                  onReceipt,
                });
              }}
            >
              Withdraw
            </Button>
          );
        },
        width: 120,
      },
    ];
  } else {
    columns = [
      {
        title: 'KAINET Staked',
        dataIndex: '0',
        render: v => formatNumber(v, decimals),
      },
      {
        title: 'KAINET Earned',
        dataIndex: 'withdrawableReward',
        render: (v, r) => <div>{getEarned(r)}</div>,
      },
      {
        title: 'Type',
        render: (v, r) => {
          const pool = pools.find(p => `${p.id}` === `${r.poolID}`);
          return (
            <TitleWithSub top={pool?.isFlexible ? 'Flexible' : `${r.lockDays} Day Lock`} sub={`APR ${r.rewardRate}%`} />
          );
        },
      },
      {
        title: 'Total Staked',
        dataIndex: 'poolID',
        render: (v, r) => {
          const pool = pools.find(p => `${p.id}` === `${v}`);
          return (
            <TitleWithSub
              top={formatNumber(pool?.totalStaked, decimals)}
              sub={r.isActive && `You: ${((100 * r.amount) / pool?.totalStaked).toFixed(2)}%`}
            />
          );
        },
      },
      {
        dataIndex: 'stakeId',
        render: (s, r) => {
          if (!r.isActive) {
            return <Button disabled>Inactive</Button>;
          }

          const available = r.isMature || r.poolID === '0';

          return (
            <Popconfirm
              title={
                available
                  ? 'Click Yes to Unstake your rewards'
                  : 'Are you sure? Your rewards are not ready and will be lost'
              }
              onConfirm={() => {
                unstake({
                  stakeId: s,
                  onProcessing,
                  onReceipt,
                });
              }}
            >
              <Button style={{ minWidth: '100px' }} block>
                Withdraw
              </Button>
            </Popconfirm>
          );
        },
        width: 120,
      },
    ];
  }

  return (
    <div>
      <h3>My Stakes</h3>
      <CreateStake {...{ createVisible, setCreateVisible }} />
      {stakeCount === 0 && <Alert message="You don't have any stakes yet - create one!" style={{ marginBottom: 20 }} />}
      {stakeCount > 0 && (
        <div>
          <Table rowKey="stakeId" pagination={false} dataSource={stakes} columns={columns} />
        </div>
      )}
      <Button
        onClick={() => {
          setCreateVisible(true);
        }}
        size="large"
        block
        type="primary"
      >
        Create Stake
      </Button>
    </div>
  );
};

const CreateStake = ({ createVisible, setCreateVisible }) => {
  const { info, onProcessing, onReceipt } = useContext(WalletContext);
  const { library } = useWeb3React();

  const [estimatedRewards, setEstimatedRewards] = useState(null);
  const [loadingRewards, setLoadingRewards] = useState(false);
  const [poolId, setPoolId] = useState(null);
  const [stakeAmount, setStakeAmount] = useState(0);
  const { pools, balance, decimals } = info;

  useEffect(() => {
    if (poolId === null) return;
    setLoadingRewards(true);

    calculate_rewards({
      provider: library.provider,
      poolId,
      stakeAmount,
    }).then(r => {
      setLoadingRewards(false);
      setEstimatedRewards(r / 10 ** decimals);
    });
  }, [poolId, stakeAmount]);

  return (
    <Modal
      bodyStyle={{
        backgroundColor: Colors.gray9,
      }}
      title="Create New Stake"
      visible={createVisible}
      onCancel={() => setCreateVisible(false)}
      footer={null}
    >
      <div>
        <h4 style={{ margin: '0px 0px 20px 0px' }}>Choose Pool</h4>
      </div>
      <Row gutter={20}>
        {pools.map(p => (
          <Col span={12} xs={24} sm={24} md={12} key={p.id}>
            <div onClick={() => setPoolId(p.id)} style={{ cursor: 'pointer' }}>
              <Card
                size="small"
                title={<b>Pool {p.id + 1}</b>}
                style={{ marginBottom: 20, border: `1px solid ${p.id === poolId ? Colors.indigo6 : 'transparent'}` }}
              >
                <div>
                  <b>Flexible:</b> {p.isFlexible ? 'Yes' : 'No'}
                </div>
                <div>
                  <b>Lock Days:</b> {p.isFlexible ? '-' : p.lockDays}
                </div>
                <div>
                  <b>APR:</b> {p.rewardRate}%
                </div>
                <div>
                  <b>Total Staked:</b> {`${p.totalStaked / 10 ** decimals}`.split('.')[0]}
                </div>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      <h4>Stake Amount</h4>
      <div style={{ display: 'flex' }}>
        <Input
          type="number"
          style={{ flex: 1 }}
          max={balance}
          min={0}
          value={stakeAmount}
          onChange={e => setStakeAmount(e.target.value)}
        />
        <Button type="primary" onClick={() => setStakeAmount(balance)}>
          MAX
        </Button>
      </div>
      {estimatedRewards !== null && (
        <div style={{ marginTop: 10, fontSize: 16 }}>
          Estimated Rewards:{' '}
          {loadingRewards ? (
            <LoadingOutlined />
          ) : (
            <span>{parseFloat(estimatedRewards * 10 ** decimals).toFixed(2)} KAINET</span>
          )}
        </div>
      )}
      <Button
        style={{ marginTop: 20 }}
        disabled={poolId === null || stakeAmount === 0}
        type="primary"
        block
        onClick={() => {
          info.stake({ poolId, stakeAmount, onProcessing, onReceipt });
        }}
      >
        Stake!
      </Button>
    </Modal>
  );
};

const Approve = () => {
  const { info, onProcessing, onReceipt } = useContext(WalletContext);

  return (
    <div style={{ marginTop: 20 }}>
      <p>Let's approve your tokens for staking first</p>
      <Button
        onClick={() => {
          info.approve({ onProcessing, onReceipt });
        }}
        size="large"
        block
      >
        Approve
      </Button>
    </div>
  );
};

const Staker = () => {
  const { info } = useContext(WalletContext);

  if (!info) return <Spin />;

  const { allowance } = info;

  return allowance === 0 ? <Approve /> : <Stakes />;
};

const Connect = () => {
  const { disconnect, info } = useContext(WalletContext);
  const { account } = useWeb3React();

  if (account) {
    return (
      <Card
        size="small"
        title={
          <div>
            <div>
              <b>Wallet</b> <Contract contract={account} />
            </div>
          </div>
        }
        extra={
          <Button
            onClick={() => {
              disconnect();
            }}
          >
            Disconnect
          </Button>
        }
      >
        <div>
          <b>KAINET Balance</b> {info?.balance}
        </div>
      </Card>
    );
  }

  return (
    <div style={{ textAlign: 'center' }}>
      <img src={logo} style={{ width: 100, margin: 'auto' }} alt="KAINET" />
      <h1>KAINET Staking</h1>
      <p>Stake your KAINET tokens and earn up to 24% APR!</p>
      <ConnectButton />
    </div>
  );
};

export default function StakeContainer() {
  const { account } = useWeb3React();

  return (
    <Wrapper>
      <Connect />
      {account && <Staker />}
    </Wrapper>
  );
}
