import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import { CONTRACT, PANCAKE_SWAP } from '../../Config';

import meta from '../../assets/images/meta.png';
import pancakeswap from '../../assets/images/logos/pancake.png';
import swap from '../../assets/images/swap.png';
import howto from '../../assets/images/howto.svg';

const propTypes = {
  ...SectionSplitProps.types,
};

const defaultProps = {
  ...SectionSplitProps.defaults,
};

class FeaturesSplit extends React.Component {
  render() {
    const {
      className,
      topOuterDivider,
      bottomOuterDivider,
      topDivider,
      bottomDivider,
      hasBgColor,
      invertColor,
      invertMobile,
      invertDesktop,
      alignTop,
      imageFill,
      ...props
    } = this.props;

    const outerClasses = classNames(
      'features-split section',
      topOuterDivider && 'has-top-divider',
      bottomOuterDivider && 'has-bottom-divider',
      hasBgColor && 'has-bg-color',
      invertColor && 'invert-color',
      className,
    );

    const innerClasses = classNames(
      'features-split-inner section-inner',
      topDivider && 'has-top-divider',
      bottomDivider && 'has-bottom-divider',
    );

    const splitClasses = classNames(
      'split-wrap',
      invertMobile && 'invert-mobile',
      invertDesktop && 'invert-desktop',
      alignTop && 'align-top',
    );

    const sectionHeader = {
      img: howto,
      // pre: 'Available on 5 Exchanges',
      title: 'How to Buy',
    };

    return (
      <section {...props} className={outerClasses} id="howto">
        <div className="container">
          <div className={innerClasses}>
            <SectionHeader data={sectionHeader} className="center-content" />
            {/* <div style={{ display: 'flex', justifyContent: 'center' }}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/FJYgse_La6M"
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div> */}
            <div className={splitClasses}>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Download <a href="https://metamask.io">MetaMask</a>
                  </h3>
                  <p className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                    This is the app you'll use to see your tokens. You can also use your existing app like TrustWallet,
                  </p>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-02 reveal-scale-up',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image src={meta} alt="Features split 01" width={528} height={396} />
                </div>
              </div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-right" data-reveal-container=".split-item">
                    Go to <a href={PANCAKE_SWAP}>PancakeSwap</a>
                  </h3>
                  <div className="m-0 reveal-from-right" data-reveal-delay="100" data-reveal-container=".split-item">
                    <ul>
                      <li>Connect your wallet on the top right</li>
                      <li>
                        In the <b>From</b> box, choose BNB if it's not already selected
                      </li>
                      <li>
                        In the <b>To</b> box, click Select Token and paste the KAINET contract {CONTRACT} if it's not
                        already there
                      </li>
                      <li>Click settings and set slippage to 12%</li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-03 reveal-scale-up',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image src={pancakeswap} alt="Features split 02" width={528} height={396} />
                </div>
              </div>
              <div className="split-item">
                <div className="split-item-content center-content-mobile">
                  <h3 className="mt-0 mb-16 reveal-from-left" data-reveal-container=".split-item">
                    Swap and Confirm
                  </h3>
                  <div className="m-0 reveal-from-left" data-reveal-delay="100" data-reveal-container=".split-item">
                    <ul>
                      <li>Click Swap</li>
                      <li>Confirm the transaction from your wallet</li>
                      <li>
                        To see your KAINET tokens in your wallet, add the token using the same contract {CONTRACT}
                      </li>
                    </ul>
                  </div>
                </div>
                <div
                  className={classNames(
                    'split-item-image center-content-mobile illustration-element-04 reveal-scale-up',
                    imageFill && 'split-item-image-fill',
                  )}
                  data-reveal-container=".split-item"
                  data-reveal-delay="200"
                >
                  <Image src={swap} alt="Features split 03" width={528} height={396} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

FeaturesSplit.propTypes = propTypes;
FeaturesSplit.defaultProps = defaultProps;

export default FeaturesSplit;
